import {Fragment} from "react";
import Sidebar from "./Blocks/Sidebar";
import Header from "./Blocks/Header";
import Authorization from "./Lib/Authorization";
import {ToastContainer} from "react-toastify";
const auth = new Authorization();
const {Route} = require("react-router-dom");

export class PanelRoute extends Route {
    render() {
        if (!auth.isLoggedIn()) {
            window.location.href = '/login'
        } else {
            const {component: Component}: PanelRoute = this.props;
            return <Fragment>
                <Sidebar {...this.props} />
                <ToastContainer />
                <main className="admin-main">
                    <Header {...this.props} />
                    <section className="admin-content pb-3">
                        <Component {...this.props} />
                    </section>
                </main>
            </Fragment>
        }
    }
}