import React from "react";
import {
    Button, Badge,
    ButtonGroup, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Modal, ModalBody, ModalFooter,
    Row, Spinner, UncontrolledButtonDropdown, UncontrolledDropdown
} from "reactstrap";
import DataTable from "../../Components/DataTable";
import moment from "moment";
import {LicensesModel} from "../../Models/Licenses";
import {ProductsModel} from "../../Models/Products";
import Select from "react-select"
import GlSelect from "../../Components/GlSelect";
import {customSelectStyles, debounce, licenseTypes} from "../../Config";
import DayPicker, {DateUtils} from "react-day-picker";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {toast} from "react-toastify";
import 'react-day-picker/lib/style.css';
import {getErrorMessage} from "../../Lib/GlRequest";

const _license_default = {
    id: null,
    domain: null,
    expiryDate: "",
    supportDate: "",
    productId: 0,
    comment: "",
    licenseType: "full",
    licenseDate: "",
    lastCheck: "",
    serial: "",
    extras: "",
    product: {}
}
const supportDateOptions = [
    {label: "Hepsi", "value": ""},
    {label: "Sona ermiş", "value": "expired"},
    {label: "Aktif", "value": "active"}
]

const LicenseModel = new LicensesModel()
const ProductModel = new ProductsModel()

const AdvancedDateInput = (props) => {
    return <InputGroup>
        <Input type={"date"} onChange={props.self.licenseDetailChange} name={props.slug}
               value={props.self.state.editLicenseDetails[props.slug]}/>
        <InputGroupAddon addonType={"append"}>
            <UncontrolledDropdown>
                <UncontrolledButtonDropdown>
                    <DropdownToggle caret>
                        Öntanımlı
                    </DropdownToggle>
                    <DropdownMenu>
                        {((typeof props.trial === "undefined") || (props.trial)) &&
                        <DropdownItem onClick={() => props.self.setExpiryDate("trial", props.slug)}>Deneme
                            Lisansı</DropdownItem>}
                        <DropdownItem onClick={() => props.self.setExpiryDate("1month", props.slug)}>1
                            Ay</DropdownItem>
                        <DropdownItem onClick={() => props.self.setExpiryDate("2month", props.slug)}>2
                            Ay</DropdownItem>
                        <DropdownItem onClick={() => props.self.setExpiryDate("3month", props.slug)}>3
                            Ay</DropdownItem>
                        <DropdownItem onClick={() => props.self.setExpiryDate("6month", props.slug)}>6
                            Ay</DropdownItem>
                        <DropdownItem onClick={() => props.self.setExpiryDate("1year", props.slug)}>1
                            Yıl</DropdownItem>
                        <DropdownItem onClick={() => props.self.setExpiryDate("full", props.slug)}>Tam
                            Sürüm</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </UncontrolledDropdown>
        </InputGroupAddon>
    </InputGroup>
}

class LicenseListPage extends React.Component {
    tableRef = null

    licenseTypesStr = ""
    defaultType = ""
    pageTitle = ""
    pageDescription = ""
    hideSupportFilter = false
    expiryDateEnd = ""
    expiryDateStart = ""

    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            sortKey: 'id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Domain',
            accessor: 'domain',
            sortKey: 'domain',
            Cell: row => {
                let visitDomain = <a target={"_blank"} rel={"noreferrer"} href={`http://${row.value}`}><i
                    className={"mdi mdi-web"}></i></a>
                return <span>{row.value} {visitDomain}</span>;
            }
        },
        {
            Header: 'Son Kullanma Tarihi',
            accessor: 'expiryDate',
            sortKey: 'expiryDate',
            Cell: row => {
                let _moment = moment(row.value)
                let isExpired = _moment < moment()
                return <span className={classnames({
                    "text-danger": isExpired
                })} title={_moment.format()}>{_moment.format("D.MM.yyyy")}</span>
            }
        },
        {
            Header: 'Ürün',
            accessor: 'product',
            Cell: data => {
                let row = data.row.original
                let usedVersion = (row.usedVersion !== null ? <Badge color={"primary"}>{`(v${row.usedVersion})`}</Badge> : "");
                let product = typeof row.product === "object" && row.product !== null ? row.product : {}
                return typeof product.name === "string" ? <Link to={`/products/${product.id}`}>{product.name} <i
                    className={"mdi mdi-arrow-right-bold-box"}></i>
                    {usedVersion}
                </Link> : "-"
            }
        },
        {
            Header: 'Destek Süresi',
            accessor: 'supportDate',
            sortKey: 'supportDate',
            Cell: row => {
                let _moment = moment(row.value)
                let isExpired = (_moment < moment())
                return <div className={"text-center"}>
                    <span className={classnames({
                        "text-danger": isExpired,
                        "text-primary": !isExpired,
                        "d-inline-block": true, "text-underline": true, "text-dark bold": true
                    })} title={row.value}>{_moment.format("D.MM.yyyy")}</span>
                    <span className={"d-block text-muted"}>{_moment.fromNow()}</span>
                </div>
            }
        },
        {
            Header: 'Güncelleme Süresi',
            accessor: 'getUpdatesUntilAt',
            sortKey: 'getUpdatesUntilAt',
            Cell: row => {
                let _moment = moment(row.value)
                let isExpired = (_moment < moment())
                return <div className={"text-center"}>
                    <span className={classnames({
                        "text-danger": isExpired,
                        "text-primary": !isExpired,
                        "d-inline-block": true, "text-underline": true, "text-dark bold": true
                    })} title={row.value}>{_moment.format("D.MM.yyyy")}</span>
                    <span className={"d-block text-muted"}>{_moment.fromNow()}</span>
                </div>
            }
        },
        {
            Header: 'Lisans Tarihi',
            accessor: 'licenseDate',
            sortKey: 'licenseDate',
            Cell: row => {
                return <div className={"text-center"}><span
                    className={"d-inline-block text-underline text-dark bold"}
                    title={row.value}
                >{moment(row.value).format("D.MM.yyyy")}</span>
                    <span className={"d-block text-muted"}>{moment(row.value).fromNow()}</span>
                </div>
            }
        },
        {
            Header: 'Son Kontrol',
            accessor: 'lastRequest',
            sortKey: 'LastRequest',
            Cell: row => {
                let _moment = moment(row.value)
                return <div>
                    <span
                        className={"d-block text-underline text-dark bold"}
                        title={row.value}
                    >{moment(row.value).format("D.MM.yyyy")}</span>
                    <span title={_moment.format()}>{_moment.fromNow()}</span>
                </div>

            }
        },
        {
            Header: 'İşlemler',
            Cell: (data) => {
                let row = data.row.original
                return <ButtonGroup>
                    <Button size={"sm"}
                            onClick={() => {
                                LicenseModel.getById(row.id).then(result => result.json())
                                    .then(result => {
                                        result.licenseDate = moment(result.licenseDate).format("YYYY-MM-DD")
                                        result.expiryDate = moment(result.expiryDate).format("YYYY-MM-DD")
                                        result.supportDate = moment(result.supportDate).format("YYYY-MM-DD")
                                        result.getUpdatesUntilAt = moment(result.getUpdatesUntilAt).format("YYYY-MM-DD")
                                        this.setState({
                                            editLicenseDetails: result,
                                            showLicenseModal: true,
                                            editLicenseId: row.id
                                        })
                                    })
                            }}
                            color={"primary"}>Düzenle</Button>
                    <Button
                        onClick={() => {
                            LicenseModel.getById(row.id).then(result => result.json())
                                .then(result => {
                                    this.setState({
                                        editLicenseDetails: result,
                                        showLicenseModal: false,
                                        deleteModalShow: true,
                                        editLicenseId: row.id
                                    })
                                })
                        }}
                        size={"sm"} color={"danger"}>Sil</Button>
                </ButtonGroup>
            }
        }
    ]

    state = {
        ShowSpinner: false,
        domainFilter: '',
        from: undefined,
        to: undefined,
        editLicenseId: null,
        showLicenseModal: false,
        editLicenseDetails: _license_default,
        deleteModalShow: false,
        showCalendar: false,
        supportDateFilter: '',
        categoriesFilter: []
    }

    showCalendarToggle = () => {
        this.setState({showCalendar: !this.state.showCalendar})
    }

    constructor(props) {
        super(props);
        this.licenseModalToggle = this.licenseModalToggle.bind(this)
        this.licenseDetailChange = this.licenseDetailChange.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.handleDayClick = this.handleDayClick.bind(this)
        this.handleResetClick = this.handleResetClick.bind(this)
        this.tableRef = React.createRef()
    }

    licenseDetailChange = (e) => {
        let name, value;
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            value = e.value
            name = e.name
        }

        if (name === "productId")
            value = parseInt(value)

        let exists = this.state.editLicenseDetails
        exists[name] = value
        this.setState({editLicenseDetails: exists})
    }

    setExpiryDate = (format, input_name) => {
        let val, date_format = "YYYY-MM-DD";

        switch (format) {
            case 'trial':
                val = moment().add("7", "days").format(date_format);
                break;
            case '1month':
                val = moment().add("1", "months").format(date_format);
                break;
            case '2month':
                val = moment().add("2", "months").format(date_format);
                break;
            case '3month':
                val = moment().add("3", "months").format(date_format);
                break;
            case '6month':
                val = moment().add("6", "months").format(date_format);
                break;
            case '1year':
                val = moment().add("1", "year").format(date_format);
                break;
            case 'full':
                val = moment().add("100", "years").format(date_format);
                break;
            default:
                val = moment()
                break;
        }
        this.licenseDetailChange({
            target: {
                name: input_name,
                value: val
            }
        })
    }

    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range, () => {
            this.tableRef.current.loadData()
        });
    }

    handleResetClick() {
        this.setState({from: undefined, to: undefined}, () => {
            this.tableRef.current.loadData()
        });
    }

    render() {
        let {
            supportDateFilter,
            showLicenseModal, editLicenseId, deleteModalShow, ShowSpinner
        } = this.state

        const {from, to} = this.state;
        const modifiers = {start: from, end: to};

        let calendarStr = ''
        if (from && to)
            calendarStr = `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`


        let selected_license_type = this.state.editLicenseDetails.licenseType || this.defaultType;
        return <div>

            <Modal isOpen={deleteModalShow} toggle={this.deleteModalToggle}>
                <ModalBody>
                    <div className={"text-center"}>
                        <h3>Silmeyi onayla?</h3>

                        <p>"{this.state.editLicenseDetails.domain}"
                            için <strong>{(this.state.editLicenseDetails.product || {}).name}</strong> ürünü
                            {' '}<code>{licenseTypes.find(item => item.value === selected_license_type).label}</code>{' '}
                            silinecektir.</p>

                        <Button color={"danger"} size={"lg"} onClick={() => {
                            this.setState({
                                deleteModalShow: false,
                                editLicenseId: null,
                                editLicenseDetails: _license_default,
                                ShowSpinner: false
                            })
                        }}>Vazgeç</Button>{' '}

                        <Button size={"lg"} color={"primary"} onClick={() => {
                            this.deleteLicense()
                        }}>
                            Onayla <i className={"mdi mdi-arrow-right"}></i>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={showLicenseModal} toggle={this.licenseModalToggle} size={"lg"}>
                <div className={"modal-header w-100"}>
                    <h5 className={"modal-title"}>{editLicenseId ? "Lisansı Düzenle" : "Yeni Lisans Ekle"}</h5>
                    <button
                        className={"close"} onClick={() => this.licenseModalToggle()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <form id={"license-form"} name={"license-form"} onSubmit={(e) => {
                        e.preventDefault();
                        this.submitLicenseForm()
                    }}>
                        <Row>
                            <Col sm={12} md={7}>
                                <FormGroup>
                                    <Label>Domain</Label>
                                    <Input onChange={this.licenseDetailChange} name={"domain"}
                                           value={this.state.editLicenseDetails.domain}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Lisans Tarihi</Label>
                                    <AdvancedDateInput slug={"licenseDate"} self={this}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Son Kullanma Tarihi</Label>
                                    <AdvancedDateInput slug={"expiryDate"} self={this}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Destek Süresi</Label>
                                    <AdvancedDateInput slug={"supportDate"} self={this} trial={false}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Güncelleme Erişim Süresi</Label>
                                    <AdvancedDateInput slug={"getUpdatesUntilAt"} self={this} trial={false}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ürün</Label>
                                    <GlSelect
                                        onChange={(val) => {
                                            this.licenseDetailChange({
                                                name: 'productId',
                                                value: val.value
                                            })
                                        }}
                                        defaultValue={typeof this.state.editLicenseDetails.product === "object" ? {
                                            value: this.state.editLicenseDetails.product.id,
                                            label: this.state.editLicenseDetails.product.name
                                        } : {}}
                                        url={"/api/Products"}
                                        searchKey='name'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Lisans Türü</Label>
                                    <Select
                                        value={licenseTypes.find(item => selected_license_type === item.value)}
                                        onChange={(selected) => this.licenseDetailChange(Object.assign({name: "licenseType"}, selected))}
                                        options={licenseTypes}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={5}>
                                <FormGroup>
                                    <Label>Serial</Label>
                                    <Input type={"text"} onChange={this.licenseDetailChange} name={"serial"}
                                           value={this.state.editLicenseDetails.serial}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Extra</Label>
                                    <Input type={"text"} onChange={this.licenseDetailChange} name={"extras"}
                                           value={this.state.editLicenseDetails.extras}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Yorum</Label>
                                    <Input type={"textarea"} onChange={this.licenseDetailChange} name={"comment"}
                                           value={this.state.editLicenseDetails.comment}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form={"license-form"} type={"submit"} color={"primary"}>Kaydet</Button>
                </ModalFooter>
            </Modal>

            <div className="bg-dark">
                <div className="container  m-b-30">
                    <div className="row">
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4>{this.pageTitle} {ShowSpinner && <Spinner/>}</h4>
                            <p>{this.pageDescription}</p>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editLicenseId: null,
                                        editLicenseDetails: {},
                                        showLicenseModal: true
                                    })
                                }}
                                color={"primary"}>Yeni Ekle <i className={"mdi mdi-plus"}></i></Button>
                        </div>
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4><i className={"mdi mdi-filter-variant"}></i> Filtrele</h4>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>
                                            Tarih Aralığı
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        onClick={this.showCalendarToggle}
                                        value={calendarStr}/>
                                    <InputGroupAddon addonType={"append"}>
                                        {(from && to) ? (
                                            <Button color={"danger"} onClick={this.handleResetClick}>
                                                <i className={"mdi mdi-close"}></i>
                                            </Button>
                                        ) : <Button onClick={() => this.showCalendarToggle()}>
                                            <i className={"mdi mdi-calendar"}></i>
                                        </Button>}
                                    </InputGroupAddon>
                                </InputGroup>
                                <Dropdown
                                    isOpen={this.state.showCalendar}
                                    toggle={this.showCalendarToggle}
                                    style={{height: 0}}
                                >
                                    <DropdownToggle
                                        className={"invisible"}>{this.state.showCalendar ? "Takvimi Gizle" : "Takvimi Göster"}</DropdownToggle>
                                    <DropdownMenu right={true}>
                                        <DayPicker
                                            className="Selectable bg-white text-dark"
                                            numberOfMonths={2}
                                            selectedDays={[this.state.dateStart, {from, to}]}
                                            modifiers={modifiers}
                                            onDayClick={this.handleDayClick}
                                        />
                                    </DropdownMenu>
                                </Dropdown>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>Domain</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={"text"} value={this.state.domainFilter}
                                           id={"domain-filter"}
                                           onChange={(e) => {
                                               this.setState({domainFilter: e.target.value})
                                               this.onDomainFilterChanged()
                                           }}
                                    />
                                    <InputGroupAddon addonType={"append"}>
                                        {this.state.domainFilter &&
                                        <Button color={"danger"} onClick={() => {
                                            this.setState({domainFilter: ''})
                                            this.onDomainFilterChanged()
                                        }}>
                                            <i className={"mdi mdi-close"}></i>
                                        </Button>}
                                        {!this.state.domainFilter && <Button onClick={() => ''}>
                                            <i className={"mdi mdi-web"}></i>
                                        </Button>}
                                    </InputGroupAddon>

                                </InputGroup>
                            </FormGroup>
                            <Row>
                                <Col sm={12} md={6}>
                                    {!this.hideSupportFilter && <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType={"prepend"}>
                                                <InputGroupText style={{textAlign: "center", width: "120px"}}>Destek
                                                    Süresi</InputGroupText>
                                            </InputGroupAddon>
                                            <Select
                                                styles={customSelectStyles}
                                                value={supportDateOptions.find(item => item.value === supportDateFilter)}
                                                options={supportDateOptions}
                                                onChange={(item) => {
                                                    let self = this
                                                    this.setState({supportDateFilter: item.value}, () => {
                                                        self.tableRef.current.loadData();
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>}
                                </Col>
                                <Col sm={12} md={6}>..</Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType={"prepend"}>
                                                <InputGroupText>Kategori</InputGroupText>
                                            </InputGroupAddon>
                                            <GlSelect
                                                placeholder={'Görüntülemek istediğiniz kategorileri seçin'}
                                                styles={customSelectStyles}
                                                isMulti
                                                onChange={(item) => {
                                                    let self = this
                                                    this.setState({categoriesFilter: item}, () => {
                                                        self.tableRef.current.loadData();
                                                    })
                                                }}
                                                defaultValue={this.state.categoriesFilter}
                                                url={"/api/Categories"}
                                                searchKey='categoryName'
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"container"}>
                <DataTable
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/Licenses",
                    filters: {
                        domain: this.state.domainFilter,
                        LicenseDateStart: this.state.from,
                        LicenseDateEnd: this.state.to,
                        types: this.licenseTypesStr,
                        SupportDateEnd: this.getSupportFilters("end"),
                        SupportDateStart: this.getSupportFilters("start"),
                        ExpiryDateStart: this.expiryDateStart,
                        ExpiryDateEnd: this.expiryDateEnd,
                        categories: (this.state.categoriesFilter).length ? this.state.categoriesFilter.map(item => item.value).join(",") : ""
                    }
                }}/>
            </div>

        </div>
    }

    getSupportFilters(filter_name) {
        let result = ""
        let {supportDateFilter} = this.state

        if (supportDateFilter !== "") {
            switch (filter_name) {
                case 'start':
                    if (supportDateFilter === "active")
                        result = moment().startOf('day').toDate()
                    break;
                case 'end':
                    if (supportDateFilter === "expired")
                        result = moment().startOf('day').toDate()
                    break;
            }
        }
        return result
    }

    licenseModalToggle() {
        this.setState({showLicenseModal: !this.state.showLicenseModal})
    }

    deleteModalToggle() {
        this.setState({deleteModalShow: !this.state.deleteModalShow})
    }

    submitLicenseForm() {
        let license_details = Object.assign({}, this.state.editLicenseDetails)

        if (typeof license_details.licenseType !== "string")
            license_details.licenseType = this.defaultType

        delete license_details.lastRequest

        if (typeof license_details.extras === "string") {
            if (license_details.extras.length)
                license_details.extras = JSON.parse(license_details.extras)
            else
                license_details.extras = null
        }

        let self = this
        self.setState({ShowSpinner: true})

        if (this.state.editLicenseId === null) {
            LicenseModel.add(license_details).then(result => {
                if (result.ok) {
                    return result.json().then(response => {
                        self.tableRef.current.loadData()
                        self.setState({
                            editLicenseDetails: {},
                            editLicenseId: null,
                            showLicenseModal: false,
                            ShowSpinner: false
                        })
                        toast.success("Lisans eklendi!")
                    })
                } else {
                    result.json().then(resultError => {
                        toast.error(getErrorMessage(resultError))
                    })
                }
            })
        } else {
            LicenseModel.updateById(this.state.editLicenseId, license_details).then(result => {
                if (result.ok) {
                    self.tableRef.current.loadData()
                    self.setState({
                        editLicenseDetails: {},
                        editLicenseId: null,
                        showLicenseModal: false,
                        ShowSpinner: false
                    })
                }
            })
        }
    }

    deleteLicense() {
        let self = this
        self.setState({ShowSpinner: true})
        LicenseModel.delete(this.state.editLicenseId).then(function () {
            self.tableRef.current.loadData()
            self.setState({
                deleteModalShow: false,
                editLicenseId: null,
                ShowSpinner: false
            })
            toast.warning("🗑️ Lisans Silindi")
        })
    }

    onDomainFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);
}

export default LicenseListPage
