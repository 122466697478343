import {Badge, Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import moment from "moment";
import DataTable from "../DataTable";
import {StatisticsModel, statTimeFormat} from "../../Models/StatisticsModel";
import React from "react";
import {Link} from "react-router-dom";

const stat_model = new StatisticsModel();
const colors = ["primary", "secondary", "success", "info", "warning", "danger", "dark"]
class ExpectedSales extends React.Component {
    never_sold_ref = React.createRef()
    state = {
        start: moment().subtract(1,'month').startOf('month'),
        end: moment().subtract(1,'month').endOf("month"),
        slug: 'lastmonth',
        data: [],
        showLimit: 10
    }

    loadData = () => {
        stat_model.getBestSellers(
            this.state.start,
            this.state.end
        ).then(r => r.json())
            .then(result => {
                this.setState({data: result})
            })
    }


    componentDidMount() {
        this.loadData()
    }

    render() {
        return <Card className={"m-b-30"}>
            <CardHeader>
                <Row>
                    <Col><h5 className={"mdi mdi-trophy"}><i></i> En Çok Satanlar</h5></Col>
                    <Col className={"text-right"}>
                        <ButtonGroup>
                            {
                                [
                                    {
                                        label: "Geçen ay",
                                        slug: 'lastmonth',
                                        start: moment().subtract(1,'month').startOf('month'),
                                        end: moment().subtract(1,'month').endOf("month")
                                    },
                                    {
                                        label: "Geçen yıl",
                                        slug: 'lastyear',
                                        start: moment().subtract(1,'year').startOf("year"),
                                        end: moment().subtract(1, 'year').endOf("year")
                                    },
                                    {
                                        label: "Bu yıl",
                                        slug: 'thisyear',
                                        start: moment().startOf('year'),
                                        end: moment().endOf("year")
                                    }
                                ].map(item => {
                                    return <Button
                                        color={this.state.slug === item.slug ? 'primary' : 'secondary'}
                                        size={"sm"}
                                        onClick={() => {
                                            let states = {}
                                            states.slug = item.slug
                                            states.start = typeof (item.start) !== "undefined" ? item.start : false
                                            states.end = typeof (item.end) !== "undefined" ? item.end : false
                                            this.setState(states, () => {
                                                this.loadData()
                                            })
                                        }}
                                    >{item.label}</Button>
                                })
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                    {this.state.data ? (this.state.data).slice(0,this.state.showLimit).map((item,index) => <span className={`mr-1 mb-1 badge badge-soft-${colors[index%(colors.length)]}`}>
                        <Link to={`/products/${item.product.id}`}>#{index+1} | {item.product.name} <strong>({item.count})</strong></Link>
                    </span>) : "-No data-"}
                {this.state.data.length > this.state.showLimit ? <p className={"pt-2 text-center"}><Button
                    onClick={() => {
                        this.setState({showLimit: (this.state.showLimit)+10})
                    }}
                    color={"dark"} size={"sm"}>
                    Daha Fazla Göster <i className={"mdi mdi-arrow-down"}></i>
                </Button></p> : ""}
            </CardBody>
        </Card>
    }
}

export default ExpectedSales