import request from "../Lib/GlRequest";
import {datetime_format} from "../Config";
import moment from "moment";
export class ProductConfigsModel{

    organizeFields = (fields) => {
        let fields_converted = {}, val, integerFields = ['productId', 'ratingCounter'], floatFields = ['rating']
        Object.keys(fields).forEach(key => {
            val = fields[key]
            if(integerFields.indexOf(key) > -1)
                val = parseInt(val)
            if(floatFields.indexOf(key) > -1)
                val = parseFloat(val)
            fields_converted[key] = val
        })
        return fields_converted
    }

    add(details) {
        details = this.organizeFields(details)
        return request.post('/api/ProductConfigs', details, 'json')
    }

    delete = (vId) => {
        return request.delete('/api/ProductVersions/' + vId)
    }

    update = (pId, details) => {
        return request.put('/api/Products/putConfigs/' + pId, this.organizeFields(details), 'json')
    }

    getById = (vId) => {
        return request.get('/api/ProductVersions/' + vId)
    }

    getProductConfig(pId) {
        return request.get('/api/Products/getConfigs/' + pId)
    }

    getMedia(pId, mType)
    {
        return request.get(`/api/ProductConfigs/download/${pId}/${mType}`)
    }

    deleteMedia(pId, mType)
    {
        return request.delete(`/api/ProductConfigs/delete/${pId}/${mType}`)
    }

}