import React from "react"
import Chart from "react-apexcharts"
import {StatisticsModel} from "../../Models/StatisticsModel";
import moment from "moment";
import "moment/locale/tr";

const StatisticModel = new StatisticsModel();

class SalesCategoryDispersion extends React.Component {
    state = {
        series: [],
        options: {
            chart: {
                type: 'pie',
                width: this.props.width
            },
            legend: {
                position: 'bottom'
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    }
                }
            }]
        },
    };

    loadSaleStatistics = async () => {

        let start = moment("2018-01-01","YYYY-mm-dd")
        let end = moment()
        let labels = [], series = [], self = this
        StatisticModel.getCategorySales(start, end).then(function (data){
            if(data.ok)
            {
                data.json().then(data => {
                    if(typeof data === "object" && data.length)
                    {
                        data.forEach(item => {
                            if(typeof item.category === "object" && item.category !== null) {
                                labels.push(item.category.name)
                                series.push(item.count)
                            }
                        })

                        let _options = Object.assign({}, self.state.options)
                        _options.labels = labels
                        self.setState({
                            series:series,
                            options: _options
                        })
                    }
                })
            }
        })
    }

    componentDidMount() {
        this.loadSaleStatistics()
    }

    render() {
        return <div id="chart-pie">
            {this.state.series.length > 0 ? <Chart options={this.state.options} series={this.state.series} type="pie" width={this.props.width}/> : ""}
        </div>;
    }
}

export default SalesCategoryDispersion