import LicenseListPage from "./LicenseListPage";

class TrialLicenses extends LicenseListPage{
    licenseTypesStr = "trial"
    defaultType = "trial"
    pageTitle = "Deneme Lisansları"
    pageDescription = "Tüm ürünler için deneme aşamasındaki lisansları görüntülüyorsunuz."
    hideSupportFilter = true
}

export default TrialLicenses