import {backendBaseUrl} from "../Config";
import Authorization from "./Authorization";

const auth = new Authorization();
class GlRequest {
    create = (url, options) => {

        if (url.indexOf("http") === -1)
            url = backendBaseUrl + url;

        return auth.getAccessToken().then(token => {
            let _headers = {
                Authorization: token
            };
            if(typeof options.headers === "object") {
                _headers = Object.assign(_headers, options.headers)
            }
            options.headers = _headers
            return fetch(url, options);
        })
    }

    post = (url, post_data, data_type, extra_options) => {
        if(typeof extra_options === "undefined")
            extra_options = {
                headers: {}
            }

        if(typeof extra_options.headers === "undefined")
            extra_options.headers = {}

        if(typeof data_type === "string" && data_type === "json") {
            post_data = JSON.stringify(post_data)
            extra_options.headers['Content-Type'] = "application/json";
        }

        let options = Object.assign({
            body: post_data,
            method: 'POST'
        }, extra_options)

        return this.create(url, options)
    }

    put = (url, post_data, data_type, extra_options) => {
        if(typeof extra_options !== "object")
            extra_options = {}
        extra_options.method = "PUT"
        return this.post(url,post_data,data_type,extra_options)
    }

    get = (url, extra_options) => {
        let options = Object.assign({
            method: 'GET',
            mode: 'cors'
        }, extra_options)

        return this.create(url, options)
    }
    delete = (url, extra_options) => {
        let options = Object.assign({
            method: 'DELETE'
        }, extra_options)

        return this.create(url, options)
    }
}
const request = new GlRequest();
export function getErrorMessage(result) {
    if(typeof result !== "object" || result === null || typeof result.detail !== "string")
        return "Bir hata oluştu."
    return result.detail
}
export default request