import React from "react";
import {
    Button,
    ButtonGroup, Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Modal, ModalBody, ModalFooter,
    Row, Spinner
} from "reactstrap";
import DataTable from "../../Components/DataTable";
import {UsersModel} from "../../Models/Users";
import {debounce} from "../../Config";
import 'react-day-picker/lib/style.css';
import {toast} from "react-toastify";
import {getErrorMessage} from "../../Lib/GlRequest";

const default_user_details = {
    name: '',
    surname: '',
    email: '',
    password: ''
}
const UserModel = new UsersModel()

var slugify = require('slugify')

class Users extends React.Component {
    tableRef = null
    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Ad Soyad',
            Cell: data => {
                let row = data.row.original
                return <span>{`${row.name} ${row.surname}`}</span>;
            }
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: row => {
                return <a href={`emailto:${row.value}`}>{row.value}</a>;
            }
        },
        {
            Header: 'İşlemler',
            Cell: (data) => {
                let row = data.row.original
                return <ButtonGroup>
                    <Button size={"sm"}
                            onClick={() => {
                                UserModel.getById(row.id).then(result => result.json())
                                    .then(result => {
                                        this.setState({
                                            editUserDetails: result,
                                            showUserModal: true,
                                            editUserId: row.id
                                        })
                                    })
                            }}
                            color={"primary"}>Düzenle</Button>
                    <Button
                        onClick={() => {
                            UserModel.getById(row.id).then(result => result.json())
                                .then(result => {
                                    this.setState({
                                        editUserDetails: result,
                                        deleteModalShow: true,
                                        editUserId: row.id
                                    })
                                })
                        }}
                        size={"sm"} color={"danger"}>Sil</Button>
                </ButtonGroup>
            }
        }
    ]

    state = {
        ShowSpinner: false,
        userNameFilter: '',
        editUserId: null,
        showUserModal: false,
        editUserDetails: default_user_details,
        deleteModalShow: false,
        emailFilter: ''
    }

    constructor(props) {
        super(props);
        this.userModalToggle = this.userModalToggle.bind(this)
        this.userDetailChange = this.userDetailChange.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.tableRef = React.createRef()
    }

    userDetailChange = (e) => {
        let name, value;
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            value = e.value
            name = e.name
        }

        if (name === "parentId")
            value = parseInt(value)

        let exists = this.state.editUserDetails

        exists[name] = value

        this.setState({editUserDetails: exists})
    }

    render() {
        let {
            showUserModal, editUserId, deleteModalShow, ShowSpinner
        } = this.state

        return <div>

            <Modal isOpen={deleteModalShow} toggle={this.deleteModalToggle}>
                <ModalBody>
                    <div className={"text-center"}>
                        <h3>Silmeyi onayla?</h3>
                        <p>"<strong>{`${this.state.editUserDetails.name} ${this.state.editUserDetails.surname}`}</strong>"
                            isimli kullanıcı silineceketir. Devam
                            etmek istiyor musunuz?</p>
                        <Button color={"danger"} size={"lg"} onClick={() => {
                            this.setState({
                                deleteModalShow: false,
                                editUserId: null,
                                ShowSpinner: false
                            })
                        }}>Vazgeç</Button>{' '}
                        <Button size={"lg"} color={"primary"} onClick={() => {
                            this.deleteUser()
                        }}>
                            Onayla <i className={"mdi mdi-arrow-right"}></i>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={showUserModal} toggle={this.userModalToggle}>
                <div className={"modal-header w-100"}>
                    <h5 className={"modal-title"}>{editUserId ? "Kullanıcıyı Düzenle" : "Yeni Kullanıcı Ekle"}</h5>
                    <button
                        className={"close"}
                        onClick={() => this.userModalToggle()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <form id={"user-form"} name={"user-form"} onSubmit={(e) => {
                        e.preventDefault();
                        this.submitUserForm()
                    }}>
                        <FormGroup>
                            <Label>İsim</Label>
                            <Input type={"text"} onChange={this.userDetailChange} name={"name"}
                                   value={this.state.editUserDetails.name}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Soyisim</Label>
                            <Input type={"text"} onChange={this.userDetailChange} name={"surname"}
                                   value={this.state.editUserDetails.surname}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>E-posta Adresi</Label>
                            <Input type={"email"} onChange={this.userDetailChange} name={"email"}
                                   value={this.state.editUserDetails.email}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Şifre</Label>
                            <Input type={"password"} onChange={this.userDetailChange} name={"password"}
                                   value={this.state.editUserDetails.password}/>
                        </FormGroup>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form={"user-form"} type={"submit"} color={"primary"}>Kaydet</Button>
                </ModalFooter>
            </Modal>

            <div className="bg-dark">
                <div className="container  m-b-30">
                    <div className="row">
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4>Kullanıcılar {ShowSpinner && <Spinner/>}</h4>
                            <p>Tüm sistem kullanıcıları listelenmektedir.</p>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editUserId: null,
                                        editUserDetails: {},
                                        showUserModal: true
                                    })
                                }}
                                color={"primary"}>Yeni Ekle <i className={"mdi mdi-plus"}></i></Button>
                        </div>
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4><i className={"mdi mdi-magnify"}></i> Filtrele</h4>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>İsim</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={"text"} value={this.state.userNameFilter}
                                           id={"user-name-filter"}
                                           onChange={(e) => {
                                               this.setState({userNameFilter: e.target.value})
                                               this.onUserNameFilterChanged()
                                           }}
                                    />
                                    <InputGroupAddon addonType={"append"}>
                                        {this.state.userNameFilter &&
                                        <Button color={"danger"} onClick={() => {
                                            this.setState({userNameFilter: ''})
                                            this.onUserNameFilterChanged()
                                        }}>
                                            <i className={"mdi mdi-close"}></i>
                                        </Button>}
                                        {!this.state.userNameFilter && <Button onClick={() => ''}>
                                            <i className={"mdi mdi-rhombus-split"}></i>
                                        </Button>}
                                    </InputGroupAddon>

                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>E-posta adresi</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={"text"} value={this.state.emailFilter}
                                           id={"user-name-filter"}
                                           onChange={(e) => {
                                               this.setState({emailFilter: e.target.value})
                                               this.onEmailFilterChanged()
                                           }}
                                    />
                                    <InputGroupAddon addonType={"append"}>
                                        {this.state.emailFilter &&
                                        <Button color={"danger"} onClick={() => {
                                            this.setState({emailFilter: ''})
                                            this.onEmailFilterChanged()
                                        }}>
                                            <i className={"mdi mdi-close"}></i>
                                        </Button>}
                                        {!this.state.emailFilter && <Button onClick={() => ''}>
                                            <i className={"mdi mdi-email"}></i>
                                        </Button>}
                                    </InputGroupAddon>

                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"container"}>
                <DataTable
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/Users",
                    filters: {
                        nameSurname: this.state.userNameFilter,
                        email: this.state.emailFilter
                    }
                }}/>
            </div>

        </div>
    }

    userModalToggle() {
        this.setState({showUserModal: !this.state.showUserModal})
    }

    deleteModalToggle() {
        this.setState({deleteModalShow: !this.state.deleteModalShow})
    }

    submitUserForm() {
        let user_details = Object.assign({}, this.state.editUserDetails)
        let self = this
        self.setState({ShowSpinner: true})
        if (this.state.editUserId === null) {
            UserModel.add(user_details).then(result => {
                if(result.ok) {
                    result.json().then(result => {
                        self.tableRef.current.loadData()
                        self.setState({
                            editUserDetails: default_user_details,
                            editUserId: null,
                            showUserModal: false,
                            ShowSpinner: false
                        })
                    })
                } else {
                    result.json().then(resultError => {
                        toast.error(getErrorMessage(resultError))
                    })
                }
            })
        } else {
            if (typeof user_details.Password !== "string" || user_details.Password === "")
                delete user_details.Password;

            UserModel.update(this.state.editUserId, user_details).then(result => {
                if (result.ok) {
                    self.tableRef.current.loadData()
                    self.setState({
                        editUserDetails: default_user_details,
                        editUserId: null,
                        showUserModal: false,
                        ShowSpinner: false
                    })
                }
            })
        }
    }

    deleteUser() {
        let self = this
        self.setState({ShowSpinner: true})
        UserModel.delete(this.state.editUserId).then(function () {
            self.tableRef.current.loadData()
            self.setState({
                deleteModalShow: false,
                editUserId: null,
                ShowSpinner: false
            })
        })
    }

    onUserNameFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    onEmailFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

}

export default Users