import React from "react"
import logo from "../assets/img/grilabs.svg"
import logoOnlyG from "../assets/img/only-g-light.png"
import MenuItems from "../Config";
import classnames from "classnames";

class Sidebar extends React.Component {
    state = {
        active_sidebar_menu: null,
        active_sidebar_menu_sub: null,
        sidebar_class_list: ['admin-sidebar'],
        pin_button_class: ["admin-pin-sidebar", "btn-ghost", "btn", "btn-rounded-circle"]
    }

    render() {
        let {history} = this.props
        let {sidebar_class_list, pin_button_class, active_sidebar_menu, active_sidebar_menu_sub} = this.state
        return <aside className={sidebar_class_list.join(" ")} onMouseEnter={() => {
            if (window.innerWidth >= 1200) {
                this.setState({sidebar_class_list: ["admin-sidebar", "sidebar-show"]})
            }
        }} onMouseLeave={() => {
            if (window.innerWidth >= 1200) {
                this.setState({sidebar_class_list: ["admin-sidebar"]})
            }
        }}>
            <div className="admin-sidebar-brand">
                <img className="admin-brand-logo" src={logo} width="100" alt="grilabs logo"/>
                {/*<span className="admin-brand-content"><a href="index.html">  atmos</a></span>*/}
                <div className="ml-auto">
                    <a class="sidebar-toggle" onClick={() => {
                        let _pin_button_class = pin_button_class;
                        let pinned_class_index = _pin_button_class.indexOf('pinned')
                        if (pinned_class_index > -1) {
                            _pin_button_class.splice(pinned_class_index, -1)
                        } else
                            _pin_button_class.push('pinned')
                        this.setState({pin_button_class: _pin_button_class})
                        let bodyClass = window.document.body.classList + '';
                        window.document.body.classList = bodyClass ? '' : 'sidebar-pinned';
                    }} className={pin_button_class.join(" ")}></a>
                    <a href="/" className="admin-logo-closed-sidebar">
                        <img src={logoOnlyG} alt="" width="32"/>
                    </a>
                    <a
                        onClick={() => {
                            document.body.classList = ""
                        }}
                        href="#" className="admin-close-sidebar"></a>
                </div>
            </div>
            <div className="admin-sidebar-wrapper js-scrollbar">
                <ul className="menu">
                    {MenuItems.map((item, num) => <li className={classnames("menu-item", {
                        active: active_sidebar_menu === num
                    })}>
                        <a onClick={() => {
                            if (typeof item.childs === "object" && item.childs.length) {
                                if (active_sidebar_menu === num)
                                    this.setState({active_sidebar_menu: null})
                                else
                                    this.setState({active_sidebar_menu: num})
                            } else {
                                history.push(item.to)
                            }
                        }}
                           className={`cursor-pointer menu-link ${typeof item.childs === "object" && item.childs.length ? "open-dropdown" : ""}`}>
                        <span className="menu-label">
                            <span
                                className="menu-name">{item.label} {typeof item.childs === "object" && item.childs.length ?
                                <span className="menu-arrow"></span> : ""}</span>
                        </span>
                            <span className="menu-icon">
                                {typeof item.count === "number" && item.count > 0 ? <span
                                    className="icon-badge badge-success badge badge-pill">{item.count}</span> : ""}
                                <i className={`icon-placeholder ${item.icon}`}></i>
                            </span>
                        </a>
                        <ul className={classnames("animate__animated sub-menu", {
                            "animate__fadeIn": active_sidebar_menu === num,
                            "d-block": active_sidebar_menu === num
                        })}>
                            {typeof item.childs === "object" && item.childs.length && (item.childs).map((item_child, num2) =>
                                <li className={classnames("menu-item", {
                                    active: active_sidebar_menu_sub === num2
                                })}>
                                    <a onClick={() => {
                                        history.push(item_child.to)
                                        this.setState({active_sidebar_menu_sub: num2})
                                    }} className="cursor-pointer menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">{item_child.label}</span>
                                </span>
                                    {typeof item_child.letter === "string" || typeof item_child.count === "number" || typeof item_child.icon === "string" ?
                                            <span className="menu-icon">
                                                {typeof item_child.count === "number" && item_child.count > 0 ? <span
                                                    className="icon-badge badge-success badge badge-pill">{item_child.count}</span> : ""}
                                                    <i className={`icon-placeholder ${typeof item_child.icon === "string" ? " " + item_child.icon : ""}`}>{typeof item_child.letter === "string" ? item_child.letter : ""}</i>
                                            </span> : ""}
                                    </a>
                                </li>)}
                        </ul>
                    </li>)}
                </ul>
            </div>
        </aside>
    }
}

export default Sidebar