import React, {useCallback} from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {toast} from "react-toastify";
import {backendBaseUrl} from "../Config";
import Authorization from "../Lib/Authorization";
import {Button, ButtonGroup, Media} from "reactstrap";

const auth = new Authorization();
const CustomDropzone = (props) => {
    let {id, type} = props
    let _url = `${backendBaseUrl}/api/ProductConfigs/upload/${id}/${type}?deleteIfExist=true`
    let token = '';
    Authorization: auth.getAccessToken().then(tok=>{
        token = tok;
    })
    const getUploadParams = ({meta}) => {
        return {
            url: _url,
            headers: {
                // "Content-Type": "multipart/form-data",
                Authorization: token
            }
        }
    }
    const handleChangeStatus = ({meta, file}, status) => {
        if (status === "done") {
            toast.success("Upload success!");
            props.fileChanged(file)
            if(props.modifyStatus) {
                props.modifyStatusChange()
            }
        }
    }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        //console.log(files)
    }

    let existFile = props.existFile ? [props.existFile] : []

    return existFile.length === 0 || props.modifyStatus ? (
        <div className={"text-center"}>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent="Drop A File"
                styles={{
                    dropzone: {width: 400, height: 200},
                    dropzoneActive: {borderColor: 'green'},
                }}
                accept="image/*"
            />
            {props.modifyStatus ? <Button className={"mt-2"} size={"sm"} color={"danger"} onClick={props.modifyStatusChange}>Vazgeç</Button> : ""}
        </div>
    ) : <div className={"text-center"}>
        <p className={"mb-2"}>
            <img width={125} className={"border rounded"} src={URL.createObjectURL(props.existFile)}  />
        </p>
        <ButtonGroup>
            <Button size={"sm"} color={"primary"} onClick={() => {
                window.open(URL.createObjectURL(props.existFile))
            }}><i className={"mdi mdi-eye"}></i></Button>
            <Button size={"sm"} onClick={props.modifyStatusChange}>Değiştir</Button>
            <Button color={"danger"} size={"sm"} onClick={() => {
                if(typeof props.delete === "function") {
                    props.delete()
                }
            }}>Sil</Button>
        </ButtonGroup>
    </div>
}

export default CustomDropzone