import request from "../Lib/GlRequest";
import {datetime_format} from "../Config";
import moment from "moment";
export class ProductVersionsModel{

    organizeFields = (fields) => {
        let fields_converted = {}, val, dateFields = ['releaseDate', 'publishDate'],
            integerFields = ['productId', 'publishVersionNumber']
        Object.keys(fields).forEach(key => {
            val = fields[key]
            if(dateFields.indexOf(key) > -1 )
                val = moment(val).format(datetime_format)
            else if(integerFields.indexOf(key) > -1)
                val = parseInt(val)
            fields_converted[key] = val
        })
        return fields_converted
    }

    add(details) {
        details = this.organizeFields(details)
        return request.post('/api/ProductVersions', details, 'json')
    }

    delete = (vId) => {
        return request.delete('/api/ProductVersions/' + vId)
    }

    update = (vId, details) => {
        return request.put('/api/ProductVersions/' + vId, details, 'json')
    }

    publishVersion = (vId, details) => {
        if(typeof details.publishVersionNumber !== "undefined")
            details.publishVersionNumber = parseFloat(details.publishVersionNumber)
        let url = '/api/ProductVersions/publish/' + vId, val, params = []
        Object.keys(details).forEach(key => {
            val = details[key]
            params.push(`${key}=${val}`)
        })
        url += '?' + params.join('&')
        return request.post(url)
    }

    updateVersionFiles = (vId) => {
        return request.get('/api/ProductVersions/updateVCS/' + vId)
    }

    downloadVersionFiles = (vId) => {
        return request.get('/api/ProductVersions/downloadVersionFile/' + vId)
    }

    revokeVersion(id) {
        return request.get(`/api/ProductVersions/revoke/${id}`)
    }
}