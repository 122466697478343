import request from "../Lib/GlRequest";
import moment from "moment";
import {datetime_format} from "../Config";

export class LicensesModel {

    add(license_details) {
        if (typeof license_details.supportDate === "string" && license_details.supportDate)
            license_details.supportDate = moment(license_details.supportDate).format(datetime_format)

        if (typeof license_details.licenseDate === "string" && license_details.licenseDate)
            license_details.licenseDate = moment(license_details.licenseDate).format(datetime_format)

        if (typeof license_details.expiryDate === "string" && license_details.expiryDate)
            license_details.expiryDate = moment(license_details.expiryDate).format(datetime_format)

        if (typeof license_details.getUpdatesUntilAt === "string" && license_details.getUpdatesUntilAt)
            license_details.getUpdatesUntilAt = moment(license_details.getUpdatesUntilAt).format(datetime_format)

        if (typeof license_details.lastCheck === "string" && license_details.lastCheck)
            license_details.lastCheck = moment(license_details.lastCheck).format(datetime_format)

        return request.post('/api/Licenses', license_details,'json')
    }

    delete = (license_id) => {
        return request.delete('/api/Licenses/' + license_id)
    }

    getById = (licenseId) => {
        return request.get('/api/Licenses/' + licenseId)
    }

    updateById(editLicenseId, license_details) {
        if (typeof license_details.supportDate === "string" && license_details.supportDate)
            license_details.supportDate = moment(license_details.supportDate).format(datetime_format)

        if (typeof license_details.licenseDate === "string" && license_details.licenseDate)
            license_details.licenseDate = moment(license_details.licenseDate).format(datetime_format)

        if (typeof license_details.expiryDate === "string" && license_details.expiryDate)
            license_details.expiryDate = moment(license_details.expiryDate).format(datetime_format)

        if (typeof license_details.getUpdatesUntilAt === "string" && license_details.getUpdatesUntilAt)
            license_details.getUpdatesUntilAt = moment(license_details.getUpdatesUntilAt).format(datetime_format)

        if (typeof license_details.lastCheck === "string" && license_details.lastCheck)
            license_details.lastCheck = moment(license_details.lastCheck).format(datetime_format)

        if(typeof license_details.id !== "undefined")
            delete license_details.id
        if(typeof license_details.product !== "undefined")
            delete license_details.product
        return request.put('/api/Licenses/' + editLicenseId, license_details,'json')
    }
}