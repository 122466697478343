import LicenseListPage from "./LicenseListPage";
import moment from "moment";

class AllLicenses extends LicenseListPage{
    licenseTypesStr = ""
    defaultType = "full"
    pageTitle = "Tüm Lisanslar"
    pageDescription = "Tüm ürünler için tür filtresi bulunmadan listeleniyor."
    expiryDateStart = moment().endOf('day').toDate()
}

export default AllLicenses