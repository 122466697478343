import React from "react"
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import SalesChart from "./../Components/Charts/SalesChart";
import SalesCategoryDispersion from "./../Components/Charts/SalesCategoryDispersion";
import moment from "moment";
import DataTable from "../Components/DataTable";
import {StatisticsModel, statTimeFormat} from "../Models/StatisticsModel";
import LicenseTypeDispersion from "../Components/Charts/LicenseTypeDispersion";
import ExpectedSales from "../Components/Charts/ExpectedSales";
import BestSellers from "../Components/Charts/BestSellers";
import CheckLastRequests from "../Components/Charts/CheckLastRequests";
import NotFoundLicenses from "./Dashboard/NotFoundLicenses";
const stat_model = new StatisticsModel()
class Dashboard extends React.Component {
    render() {
        let self = this
        return (
            <div>
                <div class="bg-dark">
                    <div class="container  m-b-30">
                        <div class="row">
                            <div class="col-12 text-white p-t-40 p-b-90">
                                <h4 class="">grilabs - Lisans Yönetim Sistemi</h4>
                                <p class="opacity-75 ">Ürün ve lisans istatistiklerini aşağıda
                                    görüntüleyeiblirsiniz.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container pull-up">
                    <Card className={"m-b-30"}>
                        <CardHeader className={"border-bottom"}>
                            <Row>
                                <Col>
                                    <h5><i className={"mdi mdi-chart-bar-stacked"}></i> Bu Ay Satış Durumu</h5>
                                </Col>
                                <Col className={"text-right"}><Button onClick={() => {
                                    this.props.history.push('/stats/sales')
                                }} color={"primary"} size={"sm"}>Tüm Dönemler <i className={"mdi mdi-arrow-right"}></i></Button></Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <SalesChart
                                start={moment().startOf("month")}
                                end={moment().endOf("month")}
                                type={"daily"}
                            />
                        </CardBody>
                    </Card>
                    <Row>
                        <Col sm={6}>
                            <ExpectedSales />
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <CardHeader>
                                    <h5>
                                        <i className={"mdi mdi-chart-pie"}></i> Lisans Türü Dağılımı</h5>
                                </CardHeader>
                                <CardBody>
                                    <LicenseTypeDispersion />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col sm={12} className={"p-t-30"}>
                            <NotFoundLicenses />
                        </Col>

                        <Col sm={12} className={"p-t-30"}>
                            <CheckLastRequests />
                        </Col>

                    </Row>
                </div>
            </div>
        );
    }
}

export default Dashboard