import LicenseListPage from "./LicenseListPage";

class DevLicenses extends LicenseListPage{
    licenseTypesStr = "dev"
    defaultType = "dev"
    pageTitle = "Geliştirici Lisansları"
    pageDescription = "Tüm ürünler için geliştirici lisanslarını görüntülüyorsunuz."
    hideSupportFilter = true
}

export default DevLicenses