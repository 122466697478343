import LicenseListPage from "./LicenseListPage";
import moment from "moment";

class ActiveLicenses extends LicenseListPage{
    licenseTypesStr = "full,trial,limited"
    defaultType = "full"
    pageTitle = "Aktif Lisanslar"
    pageDescription = "Tüm ürünler için aktif durumdaki lisansları görüntülüyorsunuz."
    expiryDateStart = moment().endOf('day').toDate()
}

export default ActiveLicenses