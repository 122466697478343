import request from "../Lib/GlRequest";
export class UsersModel{

    organize_fields = (details, upperCaseFirst) => {
        let form_data = new FormData, item;
        Object.keys(details).forEach(key =>{
            item = details[key];
            if(upperCaseFirst)
                key = key.charAt(0).toUpperCase() + key.slice(1)
            form_data.append(key, item)
        })
        return form_data;
    }

    add(user_details) {
        return request.post('/api/Users/create', this.organize_fields(user_details, true))
    }

    delete = (user_id) => {
        return request.delete('/api/Users/' + user_id)
    }

    update = (user_id, user_details) => {
        return request.put('/api/Users/' + user_id, this.organize_fields(user_details))
    }

    getById = (userId) => {
        return request.get('/api/Users/' + userId)
    }
}