import React from "react"
import logo from "../assets/img/grilabs-dark.svg"
import loginBg from "../assets/img/login-bg3.png"
import {withRouter} from 'react-router';
import Authorization from "../Lib/Authorization";
import {toast, ToastContainer} from "react-toastify";
const auth = new Authorization();
class Login extends React.Component {
    state = {
        inputs: {
            email: "",
            password: ""
        }
    }

    constructor(props) {
        super(props);
        if(auth.isLoggedIn())
            props.history.push('/dashboard');
    }

    changeInput = (event, name) =>
    {
        let _inputs = this.state.inputs
        _inputs[name] = event.target.value
        this.setState({inputs: _inputs})
    }

    login = async () => {
        let {email, password} = this.state.inputs
        let {history} = this.props

        await auth.doLogin(email,password).then(function (){
            history.push('/dashboard');
        }).catch((message) => {
            toast.error("Bilgilerinizi kontrol ederek yeniden deneyin!")
        });
    }

    render() {
        let {email, password} = this.state.inputs

        return <div className="container-fluid">
            <ToastContainer />
            <div className="row ">
                <div className="col-lg-4  bg-white">
                    <div className="row align-items-center m-h-100">
                        <div className="mx-auto col-md-8">
                            <div className="p-t-15 p-b-5 bg-gray-300 rounded text-center border-dashed m-b-70">
                                <p>
                                    <img src={logo} width="80" alt=""/>
                                </p>
                                <p className="admin-brand-content" style={{textTransform:"none"}}>
                                    l i c a n s e&nbsp;&nbsp;p a n e l
                                </p>
                            </div>
                            <h3 className="text-center p-b-20 fw-400">Giriş Yap</h3>
                            <form className="needs-validation" onSubmit={(e) => {
                                e.preventDefault()
                                this.login().then(r => "")
                            }}>
                                <div className="form-row">
                                    <div className="form-group floating-label col-md-12">
                                        <label>E-posta adresi</label>
                                        <input type="email" required
                                               value={email}
                                               onChange={(e) => this.changeInput(e,'email')}
                                               className="form-control" placeholder="E-posta adresi"
                                        />
                                    </div>
                                    <div className="form-group floating-label col-md-12">
                                        <label>Şifre</label>
                                        <input type="password"
                                               value={password} required
                                               placeholder={"Şifre"}
                                               onChange={(e) => this.changeInput(e,"password")}
                                               className="form-control "/>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary btn-block btn-lg">Giriş <i className={"mdi mdi-arrow-right"}></i></button>
                            </form>
                        </div>

                    </div>
                </div>
                <div className="col-lg-8 d-none d-md-block bg-cover"
                     style={{
                         backgroundImage: `url(${loginBg})`
                     }}
                ></div>
            </div>
        </div>
    }
}

export default withRouter(Login)