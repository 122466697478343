import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import moment from "moment";
import DataTable from "../DataTable";
import {statTimeFormat} from "../../Models/StatisticsModel";
import React from "react";

class NeverSold extends React.Component{
    never_sold_ref = React.createRef()
    state = {
        never_sold_start: false,
        never_sold_end: moment(),
        never_sold_slug: 'thismonth'
    }
    render() {
        return <Card className={"m-b-30"}>
            <CardHeader>
                <Row>
                    <Col><h5><i className={"mdi mdi-cart-off"}></i> Hiç Satılmayanlar</h5></Col>
                    <Col className={"text-right"}>
                        <ButtonGroup>
                            {
                                [
                                    {
                                        label: "Bu ay",
                                        slug: 'thismonth',
                                        start: moment().startOf("month"),
                                        end: false
                                    },
                                    {
                                        label: "Bu yıl",
                                        slug: 'thisyear',
                                        start: moment().startOf("year"),
                                        end: false
                                    },
                                    {
                                        label: "Geçen Ay",
                                        slug: 'lastmonth',
                                        start: moment().subtract(1, 'month').startOf("month"),
                                        end: moment().subtract(1, 'month').endOf("month")
                                    },
                                    {
                                        label: "Geçen Yıl",
                                        slug: 'lastyear',
                                        start: moment().subtract(1, 'year').startOf("year"),
                                        end: moment().subtract(1, 'year').endOf("year"),
                                    }
                                ].map(item => {
                                    return <Button
                                        color={this.state.never_sold_slug === item.slug ? 'primary' : 'secondary'}
                                        size={"sm"}
                                        onClick={() => {
                                            let states = {}
                                            states.never_sold_slug = item.slug
                                            states.never_sold_start = typeof (item.start) !== "undefined" ? item.start : false
                                            states.never_sold_end = typeof (item.end) !== "undefined" ? item.end : false
                                            this.setState(states, () => {
                                                this.never_sold_ref.current.loadData()
                                            })
                                        }}
                                    >{item.label}</Button>
                                })
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <DataTable
                    ref={this.never_sold_ref}
                    columns={[
                        {
                            Header: 'ID #',
                            accessor: 'id',
                            Cell: row => {
                                return "#" + row.value
                            }
                        },
                        {
                            Header: 'Adı',
                            accessor: 'name'
                        },
                        {
                            Header: 'Ürüne Git',
                            accessor: null,
                            Cell: data => {
                                let row = data.row.original
                                return <Button color={"primary"} size={"sm"} onClick={() => {
                                    console.log(row)
                                    this.props.history.push("/products/" + row.id)
                                }}>Detaylar</Button>
                            }
                        }
                    ]} dataQuery={{
                    url: "/api/Statistics/neverSold",
                    filters: {
                        StartDate: this.state.never_sold_start ? moment(this.state.never_sold_start).format(statTimeFormat) : false,
                        EndDate: this.state.never_sold_end ? moment(this.state.never_sold_end).format(statTimeFormat) : false
                    }
                }}/>
            </CardBody>
        </Card>
    }
}

export default NeverSold