import React from "react"
import {useTable} from 'react-table'
import request from "../Lib/GlRequest";
import {Button, Col, Pagination, PaginationItem, PaginationLink, Row, Spinner} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import moment from "moment";
import {datetime_format} from "../Config";

const pageSizes = [
    {label: "Sayfada 5", value: 5},
    {label: "Sayfada 10", value: 10},
    {label: "Sayfada 25", value: 25},
    {label: "Sayfada 50", value: 50},
    {label: "Sayfada 100", value: 100},
    {label: "Sayfada 250", value: 250}
]

function Table({columns, data, orderChange, orderBy, order}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setSortBy,
    } = useTable({
        columns,
        data,
        manualSortBy: true
    })

    // Render the UI for your table
    let props = getTableProps()
    return (
        <table className={"table gl-datatable table-striped"} {...props}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}
                >
                    {
                        headerGroup.headers.map(column => {
                                const sortable = typeof column.sortKey === "string" && column.sortKey !== ""
                                return <th
                                    className={sortable ? "cursor-pointer" : ""}
                                    onClick={() => {
                                        if (!sortable)
                                            return
                                        orderChange(column.sortKey)
                                    }}>{column.render('Header')}
                                    {' '}{sortable && orderBy === column.sortKey ?
                                        <Button style={{paddingLeft: 3, paddingRight: 3}} size={"sm"} color={"primary"}>
                                            {order === 'desc' ? <i className={"mdi mdi-sort-descending"}></i> :
                                                <i className={"mdi mdi-sort-ascending"}></i>}
                                        </Button> : ""}
                                </th>
                            }
                        )
                    }
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>

            {rows.length > 0 ? !props.loading && rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            }) : <tr>
                <td>&nbsp;</td>
                <td colSpan={6}>
                    <div className={"alert alert-light text-center"}>
                        <h1><i className={"mdi mdi-cloud-search-outline"}></i></h1>
                        <h5>Hiçbir veri bulunamadı.</h5>
                    </div>
                </td>
                <td>&nbsp;</td>
            </tr>}
            </tbody>
        </table>
    )
}

class DataTablePagination extends React.Component {
    getPages = () => {
        let pages = []
        for (let a = 1; a <= this.props.maxPage; a++)
            pages.push(a)
        return pages
    }

    render() {
        const pages = this.getPages();
        return <Row>
            <Col sm={6} md={4} className={"align-self-start"} style={{width: "100px"}}>
                <div className={"d-flex"}>
                    <div style={{flex:"0 0 auto",width:200}}>
                        <Select
                            menuPlacement={"top"}
                            className={"mt-2 pl-3 my-cool-select-top"}
                            defaultValue={pageSizes.find(item => item.value === this.props.pageSize)}
                            onChange={(e) => {
                                this.props.changesize(e.value)
                            }}
                            options={pageSizes}
                        />
                    </div>
                    <span style={{
                        width: "100px",
                        flex: "0 0 auto",
                        textAlign: "center",
                        lineHeight: "50px"
                    }}>
                        <i className={"mdi mdi-format-list-bulleted"}></i>
                        Toplam: <strong>{this.props.total}</strong></span>
                </div>

            </Col>
            <Col sm={2} md={1}>
                <Spinner color={"dark"} size={"sm"} className={classnames("mt-3", {"d-none": !this.props.loading})}/>
            </Col>
            <Col sm={6} md={7} className={"align-self-end"}>
                <Pagination className={classnames("mt-2 pr-3", {
                    "d-none": this.props.maxPage <= 1
                })} listClassName={"justify-content-end"}>
                    <PaginationItem disabled={this.props.page === 1}>
                        <PaginationLink onClick={() => {
                            this.props.change(this.props.page - 1)
                        }}><i className={"mdi mdi-arrow-left"}></i></PaginationLink>
                    </PaginationItem>
                    {pages.map(num => {
                        let out = []

                        if ((num + 5 === (this.props.page)) && this.props.page > 1) {
                            out.push(<PaginationItem>
                                <PaginationLink onClick={() => this.props.change(1)}>{1}</PaginationLink>
                            </PaginationItem>)
                            out.push(<PaginationItem><PaginationLink>...</PaginationLink></PaginationItem>)
                        }

                        out.push(
                            (pages.length < 10) ||
                            num === this.props.page ||
                            (
                                num - 5 < (this.props.page) &&
                                num > this.props.page
                            ) ||
                            (
                                num + 5 > (this.props.page) &&
                                num < this.props.page
                            ) ? (<PaginationItem active={this.props.page === num ? true : false}>
                                <PaginationLink onClick={() => this.props.change(num)}>{num}</PaginationLink>
                            </PaginationItem>) : '')

                        if ((num - 5 === (this.props.page)) && this.props.page !== pages.length) {
                            out.push(<PaginationItem><PaginationLink>...</PaginationLink></PaginationItem>)
                            out.push(<PaginationItem>
                                <PaginationLink onClick={() => this.props.change(pages.length)}>{pages.length}</PaginationLink>
                            </PaginationItem>)
                        }



                        return out;
                    })}
                    <PaginationItem disabled={this.props.page === this.props.maxPage}>
                        <PaginationLink onClick={() => {
                            this.props.change(this.props.page + 1)
                        }}><i className={"mdi mdi-arrow-right"}></i></PaginationLink>
                    </PaginationItem>
                </Pagination>
            </Col>
        </Row>;
    }
}

class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 10,
            maxPage: 1,
            loading: true,
            total: 0,
            orderBy: props.orderBy,
            order: props.order
        }
    }

    backup_filters = null

    componentDidMount() {
        if (typeof this.props.data !== "undefined")
            this.setState({data: this.props.data})
        else
            this.loadData()
    }

    changePage = (new_page) => {
        if (new_page === this.state.page)
            return;
        this.setState({page: new_page}, () => {
            this.loadData()
        })
    }
    changePageSize = (new_pagesize) => {
        if (new_pagesize === this.state.pageSize)
            return;
        this.setState({pageSize: new_pagesize}, () => {
            this.loadData()
        })
    }

    loadData = async () => {
        let self = this
        let {dataQuery} = this.props

        let {order, orderBy} = this.state

        this.setState({loading: true})
        if (typeof self.props.showSpinner === "function")
            self.props.showSpinner()

        let url_params = []

        if (typeof dataQuery.filters === "object") {
            Object.keys(dataQuery.filters).forEach(item => {
                let itemVal = dataQuery.filters[item];
                if (itemVal) {
                    if (typeof itemVal === "object" && itemVal instanceof Date) {
                        if (item.indexOf("DateStart"))
                            itemVal = moment(itemVal).startOf('day').format(datetime_format)
                        else if (item.indexOf("DateEnd"))
                            itemVal = moment(itemVal).endOf('day').format(datetime_format)
                        else
                            itemVal = moment(itemVal).format(datetime_format)

                    }
                    url_params.push(item + '=' + itemVal)
                }
            })
        }

        url_params.push('PageSize=' + this.state.pageSize)
        let page = this.state.page;

        if ( this.backup_filters !== null && JSON.stringify(this.backup_filters) !== JSON.stringify(url_params)) {
            page = 1;
            this.setState({page: 1})
        }
        this.backup_filters = Object.assign([],url_params);

        url_params.push('PageNumber=' + page)

        let url = dataQuery.url;
        if (url_params.length)
            url += '?' + url_params.join("&");

        if (orderBy)
            url += '&' + 'orderBy=' + orderBy + '&isAscSorting=' + (order === 'asc' ? 'true' : 'false')

        request.get(url).then(result => result.json()).then(response => {
            let result = response.data || []
            let maxPage = Math.ceil(response.total / response.pageSize)
            let total = response.total
            if (typeof result === "object" && result.length) {
                let data = []
                result.forEach((item) => {
                    data.push(item)
                })
                this.setState({data, maxPage, total}, () => {
                    if (typeof self.props.hideSpinner === "function")
                        self.props.hideSpinner()
                    this.setState({loading: false})
                })
            } else {
                this.setState({
                    data: [],
                    maxPage: 0,
                    total: 0,
                    loading: false
                }, () => {
                    if (typeof self.props.hideSpinner === "function")
                        self.props.hideSpinner()
                })
            }
        })
    }

    setOrderBy = (orderBy, dir) => {
        if (dir) dir = 'desc'
        this.setState({
            orderBy: orderBy,
            order: dir
        }, () => {
            this.loadData()
        })
    }

    orderChange = (slug) => {
        let dir = this.state.order + ''
        if (slug === this.state.orderBy)
            dir = dir === 'asc' ? 'desc' : 'asc';

        this.setState({
            orderBy: slug,
            order: dir
        }, () => {
            this.loadData()
        })
    }

    render() {
        let data = this.state.data;
        return <div className={"bg-white"}>
            <Table
                data={data}
                columns={this.props.columns}
                orderChange={this.orderChange}
                orderBy={this.state.orderBy}
                order={this.state.order}
            />
            <div className={"p-1 bg-light border-top"}>
                <DataTablePagination
                    loading={this.state.loading}
                    change={this.changePage}
                    changesize={this.changePageSize}
                    page={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.pageSize} maxPage={this.state.maxPage}/>
            </div>
        </div>
    }
}

export default DataTable