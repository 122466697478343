import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import moment from "moment";
import DataTable from "../DataTable";
import {StatisticsModel, statTimeFormat} from "../../Models/StatisticsModel";
import React from "react";

const stat_model = new StatisticsModel();
const colors = ["primary", "secondary", "success", "info", "warning", "danger", "dark"]
class ExpectedSales extends React.Component {
    never_sold_ref = React.createRef()
    state = {
        start: moment(),
        end: moment().endOf("month"),
        slug: 'thismonth',
        data: []
    }

    loadData = () => {
        stat_model.getExpectedSales(
            this.state.start,
            this.state.end
        ).then(r => r.json())
            .then(result => {
                this.setState({data: result.licenses})
            })
    }


    componentDidMount() {
        this.loadData()
    }

    render() {
        return <Card className={"m-b-30"}>
            <CardHeader>
                <Row>
                    <Col>
                        <h5>
                            <i className={"mdi mdi-timetable"}></i> Beklenen Satışlar</h5>
                    </Col>
                    <Col className={"text-right"}>
                        <ButtonGroup>
                            {
                                [
                                    {
                                        label: "Bu ay",
                                        slug: 'thismonth',
                                        start: moment(),
                                        end: moment().endOf("month")
                                    },
                                    {
                                        label: "Bu yıl",
                                        slug: 'thisyear',
                                        start: moment(),
                                        end: moment().endOf("year")
                                    }
                                ].map(item => {
                                    return <Button
                                        color={this.state.slug === item.slug ? 'primary' : 'secondary'}
                                        size={"sm"}
                                        onClick={() => {
                                            let states = {}
                                            states.slug = item.slug
                                            states.start = typeof (item.start) !== "undefined" ? item.start : false
                                            states.end = typeof (item.end) !== "undefined" ? item.end : false
                                            this.setState(states, () => {
                                                this.loadData()
                                            })
                                        }}
                                    >{item.label}</Button>
                                })
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                    {this.state.data.map((item,count) => <span className={`mr-1 mb-1 badge badge-soft-${colors[count%(colors.length)]}`}>
                        <strong>{item.domain}</strong> ({item.product.name})
                    </span>)}
            </CardBody>
        </Card>
    }
}

export default ExpectedSales