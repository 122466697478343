import React, {Component} from 'react';
import {
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import {PanelRoute} from "./PanelRoute";
import Home from "./Page/Home";
import Login from "./Page/Login";
import Dashboard from "./Page/Dashboard";
import ActiveLicenses from "./Page/Licenses/ActiveLicenses";
import Users from "./Page/Users/Users";
import TrialLicenses from "./Page/Licenses/TrialLicenses";
import ExpiredLicenses from "./Page/Licenses/ExpiredLicenses";
import DevLicenses from "./Page/Licenses/DevLicenses";
import FreeLicenses from "./Page/Licenses/FreeLicenses";
import ProductListPage from "./Page/Products/ProductListPage";
import ProductCategoryList from "./Page/Products/ProductCategoryList";
import ProductDetailPage from "./Page/Products/ProductDetailPage";
import AllLicenses from "./Page/Licenses/AllLicenses";
import ProductStats from "./Page/Stats/ProductStats";
import SalesStats from "./Page/Stats/SalesStats";

class Routes extends Component {
    render() {
        return (
            <div>
                <Switch history={this.props.history}>
                    <Route {...this.props} path="/" component={Home} exact={true} />
                    <PanelRoute {...this.props} path="/dashboard" component={Dashboard}/>
                    <Route {...this.props} path="/login" component={Login}/>
                    <PanelRoute {...this.props} path="/licenses/active" component={ActiveLicenses}/>
                    <PanelRoute {...this.props} path="/licenses/trial" component={TrialLicenses}/>
                    <PanelRoute {...this.props} path="/licenses/expired" component={ExpiredLicenses}/>
                    <PanelRoute {...this.props} path="/licenses/dev" component={DevLicenses}/>
                    <PanelRoute {...this.props} path="/licenses/free" component={FreeLicenses}/>
                    <PanelRoute {...this.props} path="/licenses/all" component={AllLicenses}/>
                    <PanelRoute {...this.props} path="/products/list" component={ProductListPage}/>
                    <PanelRoute {...this.props} path="/products/categories" component={ProductCategoryList}/>
                    <PanelRoute {...this.props} path="/products/:productId" component={ProductDetailPage}/>
                    <PanelRoute {...this.props} path="/stats/sales" component={SalesStats}/>
                    <PanelRoute {...this.props} path="/stats/products" component={ProductStats}/>
                    <PanelRoute {...this.props} path="/users/list" component={Users}/>
                </Switch>
            </div>
        );
    }
}
export default withRouter(Routes);
