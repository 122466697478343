import request from "../Lib/GlRequest";

export class ProductsModel {

    get(name)
    {
        let url = '/api/Products'
        if(name)
            url += '?name=' + name
        return request.get(url)
    }

    getCategories(pId)
    {
        let url = '/api/Products/getCategories/' + pId;
        return request.get(url)
    }

    add(product_details) {
        return request.post('/api/Products', product_details, 'json')
    }

    delete = (product_id) => {
        return request.delete('/api/Products/' + product_id)
    }

    getById = (productId) => {
        return request.get('/api/Products/' + productId)
    }

    update = (productId, product_details) => {
        return request.put('/api/Products/' + productId, product_details, 'json')
    }

    modifyCategories(productId, productCategories) {
        return request.post('/api/ProductCategoryMatches', {
            productId: productId,
            categoryId: productCategories.map(item => item.value)
        }, 'json')

    }
}