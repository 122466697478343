import React from "react"
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import BestSellers from "../../Components/Charts/BestSellers";
import NeverSold from "../../Components/Charts/NeverSold";
import ProductSaleChart from "../../Components/Charts/ProductSaleChart";
import NotSaleTrails from "../../Components/Charts/NotSaleTrails";


class ProductStats extends React.Component {
    render() {
        return (
            <div>
                <div class="bg-dark">
                    <div class="container  m-b-30">
                        <div class="row">
                            <div class="col-12 text-white p-t-40 p-b-90">
                                <h4 class="">Ürün İstatistikleri</h4>
                                <p class="opacity-75 ">Ürünlere yönelik sunulan raporları inceleyin.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container pull-up">
                    <div className={"m-b-30"}>
                        <ProductSaleChart />
                    </div>
                    <Row>
                        <Col sm={7}>
                            <BestSellers />
                            <p>&nbsp;</p>
                            <NotSaleTrails />
                        </Col>
                        <Col sm={5}>
                            <NeverSold />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default ProductStats