import React from "react"
import FormElements from "../../../Lib/FormElements";
import {Button, Col, Row} from "reactstrap";
import {ProductConfigsModel} from "../../../Models/ProductConfigs";
import {toast} from "react-toastify";
import CustomDropzone from "../../../Components/CustomDropzone";

const default_config_data = {
    productId: 0,
    slug: '',
    icon: '',
    banner: '',
    rating: 0,
    ratingCounter: 0
}
const ProductConfigModel = new ProductConfigsModel()

class ProductConfigTab extends React.Component {
    state = {
        configData: default_config_data,
        configId: null,
        existIcon: false,
        existBanner: false,
        iconModify: false,
        bannerModify: false
    }

    constructor(props) {
        super(props);
        this.configDataChanged = this.configDataChanged.bind(this)
        this.saveConfiguration = this.saveConfiguration.bind(this)
    }

    componentDidMount() {
        this.loadProductConfig()
    }

    loadProductConfig = () => {
        ProductConfigModel.getProductConfig(this.props.productId).then(r => r.json())
            .then(r => {
                if (typeof r.id === "number") {
                    let config_data = Object.assign({}, r)
                    let configId = config_data.id + ''
                    delete config_data.product
                    delete config_data.id
                    this.setState({configData: config_data, configId: configId})

                    if(config_data.icon)
                    {
                        ProductConfigModel.getMedia(this.props.productId, "icon").then(res => {
                            res.arrayBuffer().then(buf => {
                                const file = new File([buf], 'icon.jpg', { type: res.headers.get('content-type') })
                                this.setState({ existIcon: file })
                            })
                        })
                    }

                    if(config_data.banner)
                    {
                        ProductConfigModel.getMedia(this.props.productId, "banner").then(res => {
                            res.arrayBuffer().then(buf => {
                                const file = new File([buf], 'banner.jpg', { type: res.headers.get('content-type') })
                                this.setState({ existBanner: file })
                            })
                        })
                    }
                }
            })
    }

    configDataChanged = (e) => {
        let exist = Object.assign({}, this.state.configData)
        let name, value
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            name = e.name
            value = e.value
        }
        exist[name] = value
        this.setState({configData: exist})
    }

    render() {

        const formControl = new FormElements()
        formControl.addTextElement("Slug", "slug", this.state.configData.slug, this.configDataChanged)
        formControl.addRangeElement("Oylama Değeri", "rating", this.state.configData.rating, this.configDataChanged, 0, 5, 0.1)
        formControl.addTextElement("Oylama Sayaç", "ratingCounter", this.state.configData.ratingCounter, this.configDataChanged)

        return <div>
            <Row>
                <Col>
                    <h4>Ürün Konfigürasyonu</h4>
                </Col>
                <Col className={"text-right"}>
                    <Button form="configuration-form" type={"submit"}>Kaydet</Button>
                </Col>
            </Row>
            <div className={"p-3 mt-2 bg-white shadow rounded"}>
                <form
                    onSubmit={this.saveConfiguration}
                    id={"configuration-form"} name={"configuration-form"} method={"post"}>
                    {formControl.render()}
                </form>
            </div>

            <div className={"m-b-10 mt-5"}>{' '}</div>
            <h4>Medya</h4>
            <div className={"p-3 mt-2 bg-white shadow rounded"}>
                <Row>
                    <Col sm={12} md={6}>
                        <h5>İkon</h5>
                        <CustomDropzone
                            delete={() => {
                                this.deleteMedia('icon')
                            }}
                            modifyStatusChange={() => { this.setState({iconModify: !this.state.iconModify}) }}
                            modifyStatus={this.state.iconModify}
                            existFile={this.state.existIcon}
                            id={this.props.productId} type={"icon"}
                            fileChanged={(file) => {
                                this.setState({
                                    existIcon: file
                                })
                                if(this.state.configId === null)
                                    this.loadProductConfig()
                            }}
                        />
                    </Col>
                    <Col sm={12} md={6}>
                        <h5>Banner</h5>
                        <CustomDropzone
                            delete={() => {
                                this.deleteMedia('banner')
                            }}
                            modifyStatusChange={() => { this.setState({bannerModify: !this.state.bannerModify}) }}
                            modifyStatus={this.state.bannerModify}
                            fileChanged={(file) => {
                                this.setState({
                                    existBanner: file
                                })
                                if(this.state.configId === null)
                                    this.loadProductConfig()
                            }}
                            existFile={this.state.existBanner}
                            id={this.props.productId} type={"banner"} />
                    </Col>
                </Row>
            </div>

        </div>;
    }

    saveConfiguration(ev) {
        ev.preventDefault()
        let data = Object.assign({}, this.state.configData)
        data.productId = this.props.productId
        delete data.banner
        delete data.icon
        if (this.state.configId === null) {
            ProductConfigModel.add(data).then(r => {
                if (r.ok) {
                    toast.success("Değişiklikler kaydedildi!")
                }
            })
        } else {
            ProductConfigModel.update(this.props.productId, data).then(r => {
                if (r.ok) {
                    toast.success("Değişiklikler kaydedildi!")
                }
            })
        }
    }

    deleteMedia = (type) => {
        ProductConfigModel.deleteMedia(this.props.productId, type).then(r => {
            if(r.ok)
            {
                if(type === 'icon') {
                    this.setState({
                        iconModify: true
                    })
                } else {
                    this.setState({
                        bannerModify: true
                    })
                }
            }
        })
    }

}

export default ProductConfigTab