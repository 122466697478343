import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/App.css';
import reportWebVitals from './reportWebVitals';
import {Router} from "react-router-dom";
import Routes from "./Routes";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const App = () => {
    return <React.StrictMode>
        <Router history={history}>
            <Routes history={history} />
        </Router>
    </React.StrictMode>
}
ReactDOM.render(<App />,  document.getElementById('root'));
reportWebVitals();
