import request from "../Lib/GlRequest";
import {datetime_format} from "../Config";
import moment from "moment";
import typeOf from "airbnb-prop-types/src/helpers/typeOf";

export const statTimeFormat = "YYYY-MM-DD"
export class StatisticsModel{
    getMonthly(start,end) {
        let _url = '/api/Statistics/monthly?'
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        _url += '&licenseTypes=full,limited'
        return request.get(_url)
    }
    getDaily(start,end) {
        let _url = '/api/Statistics/daily?'
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        _url += '&licenseTypes=full,limited'
        return request.get(_url)
    }
    getCategorySales(start,end) {
        let _url = '/api/Statistics/categorySales?';
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        return request.get(_url);
    }
    getLicenseStatistics(start, end) {
        let _url = '/api/Statistics/license?'
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        return request.get(_url)
    }
    getExpectedSales(start, end) {
        let _url = '/api/Statistics/expectedSales?'
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        return request.get(_url)
    }
    getBestSellers(start, end) {
        let _url = '/api/Statistics/bestSellers?'
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        return request.get(_url)
    }
    mostNotTakenTrials(start, end, type) {
        let _url = '/api/Statistics/mostNotTakenTrials?'
        _url += 'StartDate=' + start.format(statTimeFormat)
        _url += '&EndDate=' + end.format(statTimeFormat)
        if(typeof type !== "undefined")
            _url += '&LicenseType=' + type
        return request.get(_url)
    }
    checkLastReqeusts(start, end, include) {
        let CustomStatTimeFormat = "YYYY-MM-DDTHH:mm:ss"
        let _url = '/api/Statistics/checkLastReqeusts?'
        _url += 'StartDate=' + start.format(CustomStatTimeFormat)
        _url += '&EndDate=' + end.format(CustomStatTimeFormat)
        if(typeof include !== "undefined")
            _url += '&include=' + include
        return request.get(_url)
    }
}