import React from "react"

import moment from "moment"
import "moment/locale/tr"
import {toast} from "react-toastify";

import {Button, Card, CardBody, CardHeader, Table, ButtonGroup} from "reactstrap";
import DataTable from "../../Components/DataTable";
import {NotFoundLicenseModel} from "../../Models/NotFoundLicenseModel";
import ConfirmationModalAlert from "../../Components/ModalAlerts/ConfirmationModalAlert";

const model = new NotFoundLicenseModel()

class NotFoundLicenses extends React.Component {
    constructor(props) {
        super(props);
        this.purgeModalToggle = this.purgeModalToggle.bind(this)
    }
    state = {
        purgeModal: false,
        loading: true,
        domainFilter: "",
        showLog: false,
        logDetails: null
    }
    tableRef = React.createRef()
    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            sortKey: 'id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Domain',
            Cell: data => {
                let row = data.row.original
                return <span>{`${row.domain}`}</span>;
            }
        },
        {
            Header: 'Ürün',
            sortKey: 'productId',
            Cell: data => {
                let row = data.row.original
                return <div>
                    <span>{row.product.name}</span>{' '}
                    <a target={"_blank"} href={`/products/${row.productId}`}><i
                        className={"mdi mdi-open-in-new"}></i></a>
                </div>;
            }
        },
        {
            Header: 'İstek Tarihi',
            sortKey: 'requestDate',
            Cell: data => {
                let row = data.row.original
                return <div>
                    {moment(row.requestDate).format("DD-MM-YYYY HH:mm")}
                </div>;
            }
        },
        {
            Header: 'İşlemler',
            Cell: (data) => {
                let row = data.row.original
                return <ButtonGroup>
                    <Button size={"sm"}
                            onClick={() => {

                            }}
                            color={"primary"}>Diğer Detaylar <i className={"mdi mdi-eye"}></i></Button>
                    <Button
                        onClick={() => {
                            model.delete(row.id).then(result => {
                                if(result.ok){
                                    toast.success("Kayıt silindi!")
                                    this.tableRef.current.loadData()
                                }
                            })
                        }}
                        size={"sm"} color={"danger"}>Sil</Button>
                </ButtonGroup>
            }
        }
    ]

    purgeModalToggle = () => {
        this.setState({purgeModal:!this.state.purgeModal})
    }


    render() {
        return <div>

            <ConfirmationModalAlert
                color={"error"}
                toggle={this.purgeModalToggle}
                icon={"mdi-trash-can"}
                isOpen={this.state.purgeModal}
                title={"Tüm Veriler Silinecek"}
                description={"Lisanssız kullanımlara yönelik tüm veriler silinecektir. Devam etmek istiyor musunuz?"}
                buttonAction={() => {
                    model.delete("purge/all").then(result => {
                        if(result.ok){
                            this.purgeModalToggle()
                            this.tableRef.current.loadData()
                        }
                    })
                }}
            />
            <Card>
                {this.state.loading && <div className={"loading-container"}></div>}
                <CardHeader>
                    <h5 className="card-title m-b-0">Kaçak Kullanım İstekleri</h5>
                    <div className="card-controls">
                        <span
                            onClick={this.purgeModalToggle}
                            className={"cursor-pointer"}>
                            <i className={"mdi mdi-trash-can-outline"}></i>
                        </span>
                        <span
                            onClick={() => {
                                this.setState({loading: true})
                                this.tableRef.current.loadData()
                            }}
                            className="cursor-pointer js-card-refresh icon">

                        </span>
                    </div>
                </CardHeader>
                <CardBody>
                    <DataTable
                        hideSpinner={() => {
                            this.setState({loading: false})
                        }}
                        ref={this.tableRef}
                        orderBy={"requestDate"}
                        order={"desc"}
                        columns={this.columns} dataQuery={{
                        url: "/api/NotFoundLicenses",
                        filters: {
                            domain: this.state.domainFilter
                        }
                    }}/>
                </CardBody>
            </Card>
        </div>
    }
}

export default NotFoundLicenses