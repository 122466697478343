import request from "../Lib/GlRequest";

export class CategoriesModel {
    add(categories_model) {
        return request.post('/api/Categories', categories_model, 'json')
    }

    delete = (category_id) => {
        return request.delete('/api/Categories/' + category_id)
    }

    getById = (category_id) => {
        return request.get('/api/Categories/' + category_id)
    }

    update = (category_id, category_details) => {
        return request.put('/api/Categories/' + category_id, category_details, 'json')
    }

}