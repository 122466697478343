import React from "react";
import {
    Button,
    ButtonGroup, Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Modal, ModalBody, ModalFooter,
    Row, Spinner
} from "reactstrap";
import DataTable from "../../Components/DataTable";
import {ProductsModel} from "../../Models/Products";
import {customSelectStyles, debounce} from "../../Config";
import 'react-day-picker/lib/style.css';
import SuccessModalAlert from "../../Components/ModalAlerts/SuccessModalAlert";
import ConfirmationModalAlert from "../../Components/ModalAlerts/ConfirmationModalAlert";
import GlSelect from "../../Components/GlSelect";

const default_product_details = {
    name: '',
    shortname: '',
    parentId: 0,
    ioncubeConfig: {}
}
const ProductModel = new ProductsModel()
var slugify = require('slugify')

class ProductListPage extends React.Component {
    tableRef = null
    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            sortKey: 'Id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Ürün Adı',
            accessor: 'name',
            sortKey: 'name',
            Cell: row => {
                return <span>{row.value}</span>;
            }
        },
        {
            Header: 'İşlemler',
            Cell: (data) => {
                let row = data.row.original
                return <ButtonGroup>

                    <Button size={"sm"}
                            color={"secondary"}
                            onClick={() => {
                                this.props.history.push(`/products/${row.id}`)
                            }}
                    >Detaylar</Button>
                    <Button
                        onClick={() => {
                            ProductModel.getById(row.id).then(result => result.json())
                                .then(result => {
                                    this.setState({
                                        editProductDetails: result,
                                        deleteModalShow: true,
                                        editProductId: row.id
                                    })
                                })
                        }}
                        size={"sm"} color={"danger"}>Sil</Button>
                </ButtonGroup>
            }
        }
    ]

    state = {
        addedProductId: '',
        productCategories: [],
        ShowSpinner: false,
        productNameFilter: '',
        productCategoryFilter: '',
        editProductId: null,
        showProductModal: false,
        editProductDetails: default_product_details,
        deleteModalShow: false,
        productAddedModal: false
    }

    productAddedModalToggle = () => {
        this.setState({
            productAddedModal: !this.state.productAddedModal
        })
    }

    productAddedButtonAction = () => {
        this.props.history.push(`/products/${this.state.addedProductId}`)
    }

    constructor(props) {
        super(props);
        this.productModalToggle = this.productModalToggle.bind(this)
        this.productDetailChange = this.productDetailChange.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.deleteProduct = this.deleteProduct.bind(this)
        this.productAddedModalToggle = this.productAddedModalToggle.bind(this)
        this.tableRef = React.createRef()
    }

    productDetailChange = (e) => {
        let name, value;
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            value = e.value
            name = e.name
        }

        if (name === "parentId")
            value = parseInt(value)

        let exists = this.state.editProductDetails

        exists[name] = value

        if (name === "name") {
            this.productDetailChange({
                name: 'shortname',
                value: this.generateShortname(value)
            })
        }

        this.setState({editProductDetails: exists})
    }

    render() {
        let {
            showProductModal, editProductId, deleteModalShow, ShowSpinner
        } = this.state

        return <div>

            <ConfirmationModalAlert
                isOpen={this.state.deleteModalShow}
                toggle={this.deleteModalToggle}
                title={`Devam etmek istediğinizden emin misiniz?`}
                description={`"${this.state.editProductDetails.name}" ürünü kalıcı olarak silinecektir.`}
                buttonAction={this.deleteProduct}
            />

            <SuccessModalAlert
                isOpen={this.state.productAddedModal}
                toggle={this.productAddedModalToggle}
                title={"Ürün Başarıyla Eklendi"}
                description={"Eklediğiniz ürünü hemen düzenleyin ve kullanıcılarla paylaşın!"}
                buttonText={"Devam"}
                buttonAction={this.productAddedButtonAction}
            />

            <Modal isOpen={showProductModal} toggle={this.productModalToggle}>
                <div className={"modal-header w-100"}>
                    <h5 className={"modal-title"}>Yeni Ürün Ekle</h5>
                    <button className={"close"} onClick={() => this.productModalToggle()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <form id={"product-form"} name={"product-form"} onSubmit={(e) => {
                        e.preventDefault();
                        this.submitProductForm()
                    }}>
                        <FormGroup>
                            <Label>Ürün Adı</Label>
                            <Input type={"text"} onChange={this.productDetailChange} name={"name"}
                                   value={this.state.editProductDetails.name}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Ürün Sistem Adı</Label>
                            <Input type={"text"} onChange={this.productDetailChange} name={"shortname"}
                                   value={this.state.editProductDetails.shortname}/>
                        </FormGroup>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form={"product-form"} type={"submit"} color={"primary"}>Kaydet</Button>
                </ModalFooter>
            </Modal>

            <div className="bg-dark">
                <div className="container  m-b-30">
                    <div className="row">
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4>Ürünler {ShowSpinner && <Spinner/>}</h4>
                            <p>Tüm ürünler listelenmektedir.</p>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editProductId: null,
                                        editProductDetails: {},
                                        showProductModal: true
                                    })
                                }}
                                color={"primary"}>Yeni Ekle <i className={"mdi mdi-plus"}></i></Button>
                        </div>
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4><i className={"mdi mdi-magnify"}></i> Filtrele</h4>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>Ürün Adı</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={"text"} value={this.state.productNameFilter}
                                           id={"product-name-filter"}
                                           onChange={(e) => {
                                               this.setState({productNameFilter: e.target.value})
                                               this.onProductFilterChanged()
                                           }}
                                    />
                                    <InputGroupAddon addonType={"append"}>
                                        {this.state.productNameFilter &&
                                        <Button color={"danger"} onClick={() => {
                                            this.setState({productNameFilter: ''})
                                            this.onProductFilterChanged()
                                        }}>
                                            <i className={"mdi mdi-close"}></i>
                                        </Button>}
                                        {!this.state.productNameFilter && <Button onClick={() => ''}>
                                            <i className={"mdi mdi-rhombus-split"}></i>
                                        </Button>}
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>Kategori</InputGroupText>
                                    </InputGroupAddon>
                                    <GlSelect
                                        placeholder={'Görüntülemek istediğiniz kategorileri seçin'}
                                        styles={customSelectStyles}
                                        isMulti
                                        onChange={(val) => {
                                            this.setState({
                                                productCategoryFilter: val
                                            }, () => {
                                                this.onProductFilterChanged()
                                            })
                                        }}
                                        defaultValue={this.state.productCategories}
                                        url={"/api/Categories"}
                                        searchKey='categoryName'
                                    />
                                </InputGroup>
                            </FormGroup>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"container"}>
                <DataTable
                    ref={this.tableRef}
                    columns={this.columns}
                    dataQuery={{
                        url: "/api/Products",
                        filters: {
                            name: this.state.productNameFilter,
                            categories: this.state.productCategoryFilter ? (this.state.productCategoryFilter).map(item => item.value) : ''
                        },
                    }}
                    orderBy={"name"}
                    order={"desc"}
                />
            </div>

        </div>
    }

    productModalToggle() {
        this.setState({showProductModal: !this.state.showProductModal})
    }

    deleteModalToggle() {
        this.setState({deleteModalShow: !this.state.deleteModalShow})
    }

    submitProductForm() {
        let product_details = Object.assign({}, this.state.editProductDetails)
        let self = this
        self.setState({ShowSpinner: true})

        ProductModel.add(product_details).then(result => result.json()).then(function (response) {
            self.tableRef.current.loadData()
            self.setState({
                editProductDetails: default_product_details,
                addedProductId: response.id,
                editProductId: null,
                showProductModal: false,
                ShowSpinner: false,
                productAddedModal: true
            })
        })
    }

    deleteProduct() {
        let self = this
        self.setState({ShowSpinner: true})
        ProductModel.delete(this.state.editProductId).then(function () {
            self.tableRef.current.loadData()
            self.setState({
                deleteModalShow: false,
                editProductId: null,
                ShowSpinner: false
            })
        })
    }

    onProductFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);

    generateShortname(value) {

        value = slugify(value, {})
        return value.toUpperCase()
    }

}

export default ProductListPage