import LicenseListPage from "./LicenseListPage";
import moment from "moment";

class ExpiredLicenses extends LicenseListPage{
    licenseTypesStr = ""
    defaultType = "limited"
    pageTitle = "Süresi Dolan Lisanslar"
    pageDescription = "Tüm ürünler için süresi dolan süreli lisansları ve deneme lisanslarını görüntülüyorsunuz."
    hideSupportFilter = true
    expiryDateEnd = moment().startOf("day").toDate()
}

export default ExpiredLicenses