import LicenseListPage from "./LicenseListPage";

class FreeLicenses extends LicenseListPage{
    licenseTypesStr = "free"
    defaultType = "free"
    pageTitle = "Ücretsiz Lisanslar"
    pageDescription = "Tüm ürünler için ücretsiz açılan lisansları görüntülüyorsunuz."
    hideSupportFilter = true
}

export default FreeLicenses