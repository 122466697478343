import React from "react"
import Chart from "react-apexcharts"
import {StatisticsModel} from "../../Models/StatisticsModel";

import moment from "moment";
import "moment/locale/tr";

import {licenseTypes} from "../../Config";

const StatisticModel = new StatisticsModel();

class LicenseTypeDispersion extends React.Component {
    state = {
        series: [],
        options: {
            chart: {
                type: 'pie',
                width: 310
            },
            legend: {
                position: 'bottom'
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    }
                }
            }]
        },
    };

        loadLicenseStatistics = async () => {

        let start = moment("2018-01-01","YYYY-mm-dd")
        let end = moment()
        let labels = [], series = [], self = this
        StatisticModel.getLicenseStatistics(start, end).then(function (data){
            if(data.ok)
            {
                data.json().then(data => {
                    if(typeof data === "object" && data.length)
                    {
                        let licenseTypeStr;
                        data.forEach(item => {
                            if(typeof item.licenseType === "string") {
                                licenseTypeStr = (licenseTypes).find(_type => item.licenseType === _type.value )
                                labels.push(licenseTypeStr.label)
                                series.push(item.count)
                            }
                        })

                        let _options = Object.assign({}, self.state.options)
                        _options.labels = labels
                        self.setState({
                            series:series,
                            options: _options
                        })
                    }
                })
            }
        })
    }

    componentDidMount() {
        this.loadLicenseStatistics()
    }

    render() {
        return <div id="chart-pie">
            {this.state.series.length > 0 ? <Chart options={this.state.options} series={this.state.series} type="pie" width={310}/> : ""}
        </div>
            ;
    }
}

export default LicenseTypeDispersion