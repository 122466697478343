import React from "react"
import {Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import IonCubeConfig from "../Components/IonCubeConfig";
import Select from "react-select";
import moment from "moment";

class FormElements {
    elements = []
    columns = 1

    getLabel = (label) => {
        return <Label>{label}</Label>
    }

    setColumns = (columns) => {
        this.columns = columns
    }

    getElementInput = (input) => {
        let out
        switch (input.type) {
            case 'text':
                out = <Input name={input.name} value={input.value} onChange={input.onChange}/>
                break;
            case 'range':
                out = <Row>
                    <Col sm={8} md={10}>
                        <Input
                            min={input.min / input.level}
                            max={input.max / input.level}
                            type={input.type}
                            name={input.name}
                            value={input.value / input.level}
                            onChange={(e) => {
                                input.onChange({
                                    name: input.name,
                                    value: (e.target.value * input.level)
                                })
                            }}/>
                    </Col>
                    <Col sm={4} md={2} className={"text-center"}>
                        <span className={"p-1 bg-dark text-white rounded"}>{(input.value).toFixed(2)}</span>
                    </Col>
                </Row>
                break;
            case 'textarea':
                out = <Input type={"textarea"} name={input.name} value={input.value} onChange={input.onChange}/>
                break;
            case 'checkbox':
                out = <div className="form-check"><Input type={"checkbox"} name={input.name} onChange={e => {
                    if (!e.target.checked)
                        input.onChange({
                            name: input.name,
                            value: input.novaluedata
                        })
                    else
                        input.onChange({
                            name: input.name,
                            value: input.valuedata
                        })
                }} className={"form-check-input"}
                                                         value={"1"} noValue={input.novalue || "0"}
                /></div>
                break;
            case 'date':
                out = <Input type={"date"} name={input.name} value={(moment(input.value).format("YYYY-MM-DD"))}
                             onChange={input.onChange}/>
                break;
            case 'ioncubeConfig':
                out = <IonCubeConfig name={input.name} value={input.value} onChange={input.onChange}/>
                break;
            case 'select':
                out = <Select
                    isMulti={input.isMulti}
                    options={input.options}
                    value={(input.options).filter(item => {
                        return (typeof input.value === "object" && input.value !== null) && (input.value).indexOf(item.value) > -1
                    })}
                    onChange={(val) => {
                        if (val === null) {
                            input.onChange({
                                name: input.name,
                                value: false
                            })
                            return
                        }
                        if (typeof val.map === "function")
                            input.onChange({
                                name: input.name,
                                value: val.map(i => i.value)
                            })
                        else
                            input.onChange({
                                name: input.name,
                                value: val.value
                            })
                    }}/>
                break;
        }
        return out
    }

    formGroup = (content, details) => {
        return <FormGroup>
            {this.getLabel(content.label)}
            {this.getElementInput(content.input)}
        </FormGroup>
    }

    addElement = (item) => {
        this.elements.push(item)
    }

    addTextElement = (label, name, value, onChange) => {
        this.addElement({
            label: label,
            input: {
                type: "text",
                name: name,
                value: value,
                onChange: onChange
            }
        })
    }

    addRangeElement = (label, name, value, onChange, min, max, level) => {
        this.addElement({
            label: label,
            input: {
                type: "range",
                name: name,
                value: typeof value === "object" && typeof value.value !== "undefined" ? value.value : value,
                onChange: onChange,
                min: min,
                max: max,
                level: level
            }
        })
    }

    addDateElement = (label, name, value, onChange) => {
        this.addElement({
            label: label,
            input: {
                type: "date",
                name: name,
                value: value,
                onChange: onChange
            }
        })
    }

    addSelectElement = (label, name, value, onChange, options, isMulti) => {
        this.addElement({
            label: label,
            input: {
                type: "select",
                name: name,
                isMulti: isMulti,
                value: value,
                onChange: onChange,
                options: options
            }
        })
    }

    addCheckboxElement = (label, name, value, onChange, valuedata, novaluedata) => {
        this.addElement({
            label: label,
            input: {
                type: "checkbox",
                name: name,
                value: value,
                onChange: onChange,
                valuedata: valuedata,
                novaluedata: novaluedata
            }
        })
    }

    addTextAreaElement = (label, name, value, onChange) => {
        this.addElement({
            label: label,
            input: {
                type: "textarea",
                name: name,
                value: value,
                onChange: onChange
            }
        })
    }

    addIonCubeConfigElement = (label, name, value, onChange) => {
        this.addElement({
            label: label,
            input: {
                type: "ioncubeConfig",
                name: name,
                value: value,
                onChange: onChange
            }
        })
    }

    render() {
        let {elements, columns} = this
        if (this.elements.length === 0)
            return ""
        let item, output = [],
            elementKeys = Object.keys(elements),
            elementKeysArray = [], groupSize = elementKeys.length / columns

        for (let a = 0; a < columns; a++) {
            elementKeysArray[a] = []
            elementKeys.forEach((item, i) => {
                if (i < ((a + 1) * groupSize) && i >= ((a) * groupSize))
                    elementKeysArray[a].push(item);
            })
        }
        let groupOutputs = []
        elementKeysArray.forEach(keys => {
            groupOutputs = []
            keys.forEach(key => {
                item = elements[key]
                groupOutputs.push(this.formGroup(item))
            })
            output.push(<Col sm={12} md={12/columns}>{groupOutputs}</Col>)
        })

        return <Row>{output}</Row>
    }

}

export default FormElements