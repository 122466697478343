import React from "react";
import {
    Button, ButtonGroup,
    Col,
    FormGroup,
    Input,
    Label,
    Modal, ModalBody, ModalFooter,
    Row, Spinner, TabContent, TabPane
} from "reactstrap";
import DataTable from "../../Components/DataTable";
import {ProductsModel} from "../../Models/Products";
import {matchPath, withRouter} from "react-router";
import moment from "moment";
import classnames from "classnames";
import GlSelect from "../../Components/GlSelect";
import ProductVersionTab from "./Versions/ProductVersionTab";
import ProductConfigTab from "./Configs/ProductConfigTab";
import IonCubeConfig from "../../Components/IonCubeConfig";

const ProductModel = new ProductsModel()
const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/products/:productId`,
    });
    return (matchProfile && matchProfile.params) || {};
};

class ProductDetailPage extends React.Component {
    tableRef = null
    productId = null
    loadProductLock = false

    state = {
        selected_tab: 'licenses',
        showProductModal: false, editProductId: '', ShowSpinner: false,
        editProductDetails: {
            name: '', shortname: '', ioncubeConfig: {}
        },
        deleteModalShow: false,
        productDetails: {},
        productCategories: [],
        productParentSelected: false
    }

    columnsLicenseTable = [
        {
            Header: 'ID #',
            accessor: 'id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Domain',
            accessor: 'domain',
            Cell: row => {
                let visitDomain = <a target={"_blank"} rel={"noreferrer"} href={`http://${row.value}`}><i
                    className={"mdi mdi-web"}></i></a>
                return <span>{row.value} {visitDomain}</span>;
            }
        },
        {
            Header: 'Son Kullanma Tarihi',
            accessor: 'expiryDate',
            Cell: row => {
                let _moment = moment(row.value)
                let isExpired = _moment < moment()
                return <span className={classnames({
                    "text-danger": isExpired
                })} title={_moment.format()}>{_moment.format("D.MM.yyyy")}</span>
            }
        },
        {
            Header: 'Destek Süresi',
            accessor: 'supportDate',
            Cell: row => {
                let _moment = moment(row.value)
                let isExpired = (_moment < moment())
                return <span className={classnames({
                    "text-danger": isExpired,
                    "text-primary": !isExpired,
                })} title={row.value}>{_moment.format("D.MM.yyyy")}</span>
            }
        },
        {
            Header: 'Lisans Tarihi',
            accessor: 'licenseDate',
            Cell: row => {
                return <span title={row.value}>{moment(row.value).format("D.MM.yyyy")}</span>
            }
        },
        {
            Header: 'Son Kontrol',
            accessor: 'lastRequest',
            Cell: row => {
                let _moment = moment(row.value)
                return <span title={_moment.format()}>{_moment.fromNow()}</span>
            }
        }
    ]

    constructor(props) {
        super(props);
        let params = getParams(props.location.pathname)
        this.productId = params.productId
        this.tableRef = React.createRef()
        this.versionTableRef = React.createRef()
        this.productModalToggle = this.productModalToggle.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.loadProduct()
    }

    loadProduct = () => {
        if (this.loadProductLock)
            return false
        this.loadProductLock = true
        ProductModel.getById(this.productId).then(r => r.json()).then(result => {
            let data = Object.assign({}, result)

            data.parentId = typeof data.parent !== "undefined" && data.parent !== null && typeof data.parent.id !== "undefined" ? data.parent.id : false
            if(data.parentId)
            {
                this.setState({
                    productParentSelected: {
                        label: data.parent.name,
                        value: data.parent.id
                    }
                })
            }

            this.setState({
                productDetails: data,
                editProductDetails: data
            })
            this.loadProductLock = false
        })

        ProductModel.getCategories(this.productId).then(r => r.json()).then(result => {
            if (typeof result.status !== "undefined" && result.status === 404)
                return false;

            let category = result.data
            if (typeof category === "object" && category !== null && category.length)
                category = (category).map(function (item) {
                    return {
                        label: item.category.name,
                        value: item.category.id
                    }
                })
            this.setState({
                productCategories: category
            })
        })
    }


    componentDidUpdate(prevProps, prevState) {
        let prevPathname = prevProps.location.pathname
        let pathname = this.props.location.pathname
        if (prevPathname !== pathname) {
            let params = getParams(pathname)
            this.productId = params.productId;
            this.loadProduct()
        }
    }

    productModalToggle = () => {
        this.setState({showProductModal: !this.state.showProductModal})
    }

    deleteModalToggle = () => {
        this.setState({deleteModalShow: !this.state.deleteModalShow})
    }

    productDetailChange = (e) => {
        let name, value
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            name = e.name
            value = e.value
        }
        let exists = this.state.editProductDetails
        exists[name] = value
        this.setState({editProductDetails: exists})
    }

    render() {
        let {
            showProductModal, deleteModalShow, ShowSpinner
        } = this.state

        return <div>

            <Modal isOpen={deleteModalShow} toggle={this.deleteModalToggle}>
                <ModalBody>
                    <div className={"text-center"}>
                        <h3>Silmeyi onayla?</h3>
                        <p>"<strong>{this.state.editProductDetails.name}</strong>" isimli ürün silineceketir. Devam
                            etmek istiyor musunuz?</p>
                        <Button color={"danger"} size={"lg"} onClick={() => {
                            this.setState({
                                deleteModalShow: false,
                                editProductId: null,
                                ShowSpinner: false
                            })
                        }}>Vazgeç</Button>{' '}
                        <Button size={"lg"} color={"primary"} onClick={() => {
                            this.deleteProduct()
                        }}>
                            Onayla <i className={"mdi mdi-arrow-right"}></i>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={showProductModal} toggle={this.productModalToggle}>
                <div className={"modal-header w-100"}>
                    <h5 className={"modal-title"}>Ürünü Düzenle</h5>
                    <button type="button" className="close"
                            onClick={() => this.productModalToggle()}
                            aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <form id={"product-form"} name={"product-form"} onSubmit={(e) => {
                        e.preventDefault();
                        this.submitProductForm()
                    }}>
                        <FormGroup>
                            <Label>Ürün Adı</Label>
                            <Input type={"text"} onChange={this.productDetailChange} name={"name"}
                                   value={this.state.editProductDetails.name}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Ürün Sistem Adı</Label>
                            <Input type={"text"} onChange={this.productDetailChange} name={"shortname"}
                                   value={this.state.editProductDetails.shortname}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Kategoriler</Label>
                            <GlSelect
                                isMulti
                                onChange={(val) => {
                                    this.setState({
                                        productCategories: val
                                    })
                                }}
                                defaultValue={this.state.productCategories}
                                url={"/api/Categories"}
                                searchKey='categoryName'
                                placeholder={"Ürün Kategorileri"}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>ionCube Ayarları</Label>
                            <IonCubeConfig name={'ioncubeConfig'}
                                           onChange={this.productDetailChange}
                                           value={this.state.editProductDetails.ioncubeConfig} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Üst Ürün</Label>
                            <GlSelect
                                isClearable
                                name={"parentId"}
                                onChange={(val) => {
                                    this.productDetailChange({
                                        name: "parentId",
                                        value: val.value
                                    })
                                    this.setState({
                                        productParentSelected: val
                                    })
                                }}
                                defaultValue={this.state.productParentSelected}
                                url={"/api/Products"}
                                searchKey='name'
                                placeholder={"Bağlı olan üst ürünü seçiniz"}
                            />
                        </FormGroup>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form={"product-form"} type={"submit"} color={"primary"}>Kaydet</Button>
                </ModalFooter>
            </Modal>

            <div className="bg-dark">
                <div className="container  m-b-30">
                    <div className="row">
                        <div className="col-12 text-white p-t-40 p-b-30">
                            <div className={"border-bottom pb-2 mb-3"}>
                                <h4>
                                    <span>{this.state.productDetails.name}</span>
                                    <Button
                                        onClick={() => this.props.history.push('/products/list')}
                                        className={"float-right"} color={"primary"} size={"sm"}><i
                                        className={"mdi mdi-settings"}></i> Değiştir</Button> {ShowSpinner &&
                                <Spinner/>}</h4>
                                <p className={"mb-0"}>Seçili ürün için, ürün/lisans/versiyon işlemleri yapın.</p>
                            </div>


                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        {[
                                            {
                                                slug: 'licenses',
                                                label: "Ürün Lisansları",
                                                icon: "mdi mdi-layers"
                                            },
                                            {
                                                slug: 'versions',
                                                label: "Versiyonlar",
                                                icon: "mdi mdi-git"
                                            },
                                            {
                                                slug: 'settings',
                                                label: "Ayarlar",
                                                icon: "mdi mdi-settings"
                                            }
                                        ].map(item => <Button onClick={() => this.setState({
                                            selected_tab: item.slug
                                        })} color={item.slug === this.state.selected_tab ? "primary" : "light"}>
                                            <i className={item.icon}></i> {item.label}</Button>)}
                                    </ButtonGroup>
                                </Col>
                                <Col className={"text-right"}>
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => {
                                                this.props.history.push('/licenses/active')
                                            }}
                                            color={"info"}>Lisans Ekle <i className={"mdi mdi-plus"}></i></Button>
                                    </ButtonGroup>
                                    {' '}
                                    <ButtonGroup>
                                        <Button
                                            onClick={this.productModalToggle}
                                            color={"warning"}>Ürünü Düzenle <i
                                            className={"mdi mdi-pencil"}></i></Button>
                                        <Button
                                            onClick={this.deleteModalToggle}
                                            color={"danger"}>Ürünü Sil <i className={"mdi mdi-remove"}></i></Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <TabContent activeTab={this.state.selected_tab}>
                <TabPane tabId={"licenses"}>
                    <div className={"container"}>
                        <h4>Aktif Lisanslar</h4>
                        <DataTable
                            ref={this.tableRef}
                            columns={this.columnsLicenseTable} dataQuery={{
                            url: "/api/Licenses",
                            filters: {
                                ExpiryDateStart: moment().endOf('day').toDate(),
                                ProductId: this.productId,
                                types: 'full,limited'
                            }
                        }}/>
                    </div>
                    <p>&nbsp;</p>
                    <div className={"container"}>
                        <h4>Pasif Lisanslar</h4>
                        <DataTable
                            ref={this.tableRef}
                            columns={this.columnsLicenseTable} dataQuery={{
                            url: "/api/Licenses",
                            filters: {
                                ExpiryDateEnd: moment().startOf('day').toDate(),
                                ProductId: this.productId
                            }
                        }}/>
                    </div>
                    <p>&nbsp;</p>
                </TabPane>
                <TabPane tabId={"versions"}>
                    <div className={"container"}>
                        <ProductVersionTab productId={this.productId}/>
                    </div>
                </TabPane>
                <TabPane tabId={"settings"}>
                    <div className={"container"}>
                        <ProductConfigTab productId={this.productId}/>
                    </div>
                </TabPane>
                <TabPane tabId={"vcs"}></TabPane>
            </TabContent>

        </div>
    }

    submitProductForm() {
        let product_details = Object.assign({}, this.state.editProductDetails)
        let self = this
        self.setState({ShowSpinner: true})

        delete product_details.id
        delete product_details.updated
        delete product_details.created
        delete product_details.isParent
        product_details.parentId = (product_details.parentId || 0)
        delete product_details.parent



        ProductModel.update(this.productId, product_details).then(result => {
            if (result.ok) {
                ProductModel.modifyCategories(this.productId, this.state.productCategories).then(r => r.json())
                    .then(r => {
                        self.setState({
                            editProductId: null,
                            showProductModal: false,
                            ShowSpinner: false
                        })
                    })
            }
        })
    }

    deleteProduct() {
        let self = this
        self.setState({ShowSpinner: true})
        ProductModel.delete(this.productId).then(function () {
            self.props.history.push('/products/list')
        })
    }

}

export default withRouter(ProductDetailPage)