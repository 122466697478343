import React from "react"
import {
    Badge,
    Button, ButtonGroup,
    Col,
    FormGroup,
    Input, InputGroup, InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, PopoverBody, PopoverHeader, Progress,
    Row, UncontrolledPopover,
    UncontrolledTooltip
} from "reactstrap";
import DataTable from "../../../Components/DataTable";
import FormElements from "../../../Lib/FormElements";
import {ProductVersionsModel} from "../../../Models/ProductVersions";
import {datetime_format, ionCubeConfigDefault, ionCubeConfigDefaultAlt, phpVersions} from "../../../Config";
import moment from "moment";
import classnames from "classnames";
import SuccessModalAlert from "../../../Components/ModalAlerts/SuccessModalAlert";
import ConfirmationModalAlert from "../../../Components/ModalAlerts/ConfirmationModalAlert";
import {toast} from "react-toastify";

const default_version_data = {
    "productId": 0,
    "versionName": "",
    "releaseDate": "",
    "phpVersion": "",
    "ioncubeConfig": {
        "slug": "",
        "encoderVersions": [
            ""
        ],
        "ignore": [
            ""
        ],
        "copy": [
            ""
        ],
        "commit_sha": ""
    },
    "releaseNotes": "",
    "privateReleaseNotes": "",
    "releaseType": "",
    "releasePriority": ""
}

const VersionModel = new ProductVersionsModel();

class ProductVersionTab extends React.Component {
    state = {
        addVersionModal: false,
        versionData: default_version_data,
        publishVersionNumber: '',
        editingVersion: null,
        updatingVersionId: '',
        updatedVersionModal: false,
        updatingVersionModal: false,
        updateConfirmModal: false,
        publishVersionModal: false,
        revokeVersionModal: false,
        deleteVersionModal: false,
        downloadVersionModal: false,
        downloadProgress: 0
    }

    versionTableRef = null
    versionTableColumns = [
        {
            Header: 'ID #',
            accessor: 'id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Versiyon Adı',
            // accessor: 'versionName',
            Cell: data => {
                let row = data.row.original
                return <div>
                    {row.versionName}{typeof row.publishVersionNumber !== "undefined" && row.status !== "draft" && row.publishVersionNumber !== "" ? ` (${row.publishVersionNumber})` : ''}
                </div>
            }
        },
        {
            Header: 'Yayınlama',
            Cell: data => {
                let row = data.row.original
                if (row.status === 'draft')
                    return <div>
                        <Badge color={"danger"}>Yayınlanmadı</Badge>
                        {' '}
                        <Button
                            onClick={() => {
                                this.setState({
                                    publishVersionDetails: row
                                })
                                this.publishVersionModalToggle()
                            }}
                            color={"primary"} size={"sm"}>Şimdi Yayınla</Button>
                    </div>
                else
                    return <div>
                        {`${moment(row.publishDate).format('DD-MM-YYYY')} tarihinde yayınlandı`}
                        {' '}
                        <UncontrolledTooltip target={`revoke-${row.id}`}>Yayından Kaldır</UncontrolledTooltip>
                        <Button
                            id={`revoke-${row.id}`}
                            className={"btn-rounded-circle"}
                            size={"sm"} onClick={() => {
                            this.setState({
                                publishVersionDetails: row
                            })
                            this.revokeVersionModalToggle()
                        }} color={"primary"}><i className={"mdi mdi-delete-variant"}></i></Button>
                    </div>

            }
        },
        {
            Header: 'Notlar',
            Cell: data => {
                let row = data.row.original
                let notes = row.releaseNotes || ''
                let devNotes = row.privateReleaseNotes || ''

                let output = [], output1 = []

                if (devNotes) {
                    output1.push(
                        <UncontrolledTooltip placement={"left"} target={`devnote-${row.id}`}><div dangerouslySetInnerHTML={{__html:devNotes}}></div></UncontrolledTooltip>
                    )
                }

                if (notes) {
                    output1.push(
                        <UncontrolledTooltip placement={"left"} target={`note-${row.id}`}><div dangerouslySetInnerHTML={{__html:notes}}></div></UncontrolledTooltip>
                    )
                }
                notes = (notes ?
                    <Button size={"sm"} color={"info"} id={`note-${row.id}`}><i
                        className={"mdi mdi-comment"}></i></Button> : "")
                devNotes = (devNotes ? <Button size={"sm"} color={"dark"} id={`devnote-${row.id}`}><i
                    className={"mdi mdi-sunglasses"}></i></Button> : "")

                output.push(notes)
                output.push(devNotes)

                return <div>
                    {output1}
                    <ButtonGroup>{output}</ButtonGroup>
                </div>

            }
        },
        {
            Header: 'İşlemler',
            Cell: data => {
                let row = data.row.original
                return <div>
                    <UncontrolledTooltip target={`download-button${row.id}`}>Dosyaları İndir</UncontrolledTooltip>
                    <UncontrolledTooltip target={`modify-button${row.id}`}>Düzenle</UncontrolledTooltip>
                    <UncontrolledTooltip target={`update-button${row.id}`}>Dosyaları Güncelle</UncontrolledTooltip>

                    <ButtonGroup>
                        <Button
                            size={"sm"}
                            onClick={() => {
                                let row_data = Object.assign({}, row)
                                row_data.phpVersion = JSON.parse(row_data.phpVersion)
                                row_data.ioncubeConfig = JSON.parse(row_data.ioncubeConfig)
                                this.setState({
                                    versionData: row_data,
                                    editingVersion: row.id,
                                    addVersionModal: true
                                })
                            }}
                            color={"dark"} id={`modify-button${row.id}`}><i className={"mdi mdi-pencil"}></i></Button>
                        <Button
                            size={"sm"}
                            onClick={() => {
                                this.setState({downloadVersionModal: true})
                                this.downloadVersionFiles(row.id)
                            }}
                            color={"warning"} id={`download-button${row.id}`}><i
                            className={"mdi mdi-download"}></i></Button>
                        <Button
                            size={"sm"}
                            id={`update-button-v${row.id}`}
                            onClick={() => {
                                this.setState({updatingVersionId: row.id})
                                this.updateConfirmModalToggle()
                            }}
                            color={"primary"} id={`update-button${row.id}`}>
                            <i className={classnames("mdi mdi-rotate-3d", {
                                "mdi-spin": row.id === this.state.updatingVersionId
                            })}></i>
                        </Button>
                        <Button
                            size={"sm"}
                            onClick={() => {
                                this.setState({
                                    editingVersion: row,
                                    deleteVersionModal: true
                                })
                            }}
                            color={"danger"} id={`delete-button${row.id}`}><i
                            className={"mdi mdi-delete"}></i></Button>
                    </ButtonGroup>
                </div>
            }
        }
    ]
    updateVersionFiles = (vId) => {

        if (!vId)
            vId = this.state.updatingVersionId

        this.setState({updatingVersionId: vId, updatingVersionModal: true})
        VersionModel.updateVersionFiles(vId).then(r => r.json()).then(r => {
            this.setState({
                updatingVersionId: '',
                updatingVersionModal: false,
                updatedVersionModal: true
            })
        })
    }

    publishVersionFiles = () => {
        let id = this.state.publishVersionDetails.id
        VersionModel.publishVersion(id, {
            status: 'active',
            publishDate: moment().format(datetime_format),
            publishVersionNumber: this.state.publishVersionNumber
        }).then(r => r.json()).then(r => {
            this.versionTableRef.current.loadData()
        })
    }
    revokeVersionFiles = () => {
        let id = this.state.publishVersionDetails.id
        VersionModel.revokeVersion(id).then(r => r.json()).then(r => {
            this.versionTableRef.current.loadData()
            this.revokeVersionModalToggle()
        })
    }


    constructor(props) {
        super(props);
        this.versionTableRef = React.createRef()
        this.versionDataChanged = this.versionDataChanged.bind(this)
        this.publishVersionModalToggle = this.publishVersionModalToggle.bind(this)
        this.deleteVersionModalToggle = this.deleteVersionModalToggle.bind(this)
        this.downloadVersionModalToggle = this.downloadVersionModalToggle.bind(this)
        this.createVersion = this.createVersion.bind(this)
        this.updatedVersionModalToggle = this.updatedVersionModalToggle.bind(this)
        this.updateConfirmModalToggle = this.updateConfirmModalToggle.bind(this)
    }

    versionDataChanged = (e) => {
        let exist = Object.assign({}, this.state.versionData)
        let name, value
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            name = e.name
            value = e.value
        }
        exist[name] = value
        this.setState({versionData: exist})
    }

    versionModalToggle = () => {
        this.setState({addVersionModal: !this.state.addVersionModal})
    }

    updatedVersionModalToggle = () => {
        this.setState({updatedVersionModal: !this.state.updatedVersionModal})
    }
    updateConfirmModalToggle = () => {
        this.setState({updateConfirmModal: !this.state.updateConfirmModal})
    }
    publishVersionModalToggle = () => {
        this.setState({publishVersionModal: !this.state.publishVersionModal})
    }
    revokeVersionModalToggle = () => {
        this.setState({revokeVersionModal: !this.state.revokeVersionModal})
    }
    deleteVersionModalToggle = () => {
        this.setState({deleteVersionModal: !this.state.deleteVersionModal})
    }
    downloadVersionModalToggle = () => {
        this.setState({downloadVersionModal: !this.state.downloadVersionModal})
    }

    render() {
        let {productId} = this.props
        let {addVersionModal, versionData, editingVersion} = this.state
        const formControl = new FormElements()
        formControl.setColumns(2)
        formControl.addTextElement("Versiyon Adı", "versionName", versionData.versionName, this.versionDataChanged)
        // formControl.addDateElement("Versiyon Tarihi", "releaseDate", versionData.releaseDate, this.versionDataChanged)
        formControl.addSelectElement("PHP Version", "phpVersion", versionData.phpVersion, this.versionDataChanged, phpVersions, true)
        formControl.addIonCubeConfigElement("ionCube Konfigürasyonu", "ioncubeConfig", versionData.ioncubeConfig, this.versionDataChanged)
        formControl.addTextAreaElement("Versiyon Notları", "releaseNotes", versionData.releaseNotes, this.versionDataChanged)
        formControl.addTextAreaElement("Geliştirici Notları", "privateReleaseNotes", versionData.privateReleaseNotes, this.versionDataChanged)
        formControl.addTextElement("Versiyon Türü", "releaseType", versionData.releaseType, this.versionDataChanged)
        formControl.addTextElement("Öncelik", "releasePriority", versionData.releasePriority, this.versionDataChanged)


        return <div>
            <ConfirmationModalAlert
                color={"info"}
                toggle={() => {
                    this.setState({updatingVersionId: ''})
                    this.publishVersionModalToggle()
                }}
                icon={"mdi-rocket"}
                isOpen={this.state.publishVersionModal}
                title={"Versiyon Yayınla"}
                description={"Yayınladığınız versiyon tüm kullanıcılar tarafından erişilebilecektir."}
                buttonAction={() => {
                    if (!parseFloat(this.state.publishVersionNumber)) {
                        toast.error("Lütfen geçerli bir versiyon numarası giriniz!", {
                            position: "top-center"
                        })
                        return false;
                    }
                    this.publishVersionModalToggle()
                    this.publishVersionFiles()
                }}
            >
                <form>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={"prepend"}>
                                Versiyon Numarası
                            </InputGroupAddon>
                            <Input type={"text"} name={"publishVersionNumber"}
                                   onChange={(e) => {
                                       this.setState({
                                           publishVersionNumber: e.target.value
                                       })
                                   }}
                            />
                        </InputGroup>
                    </FormGroup>
                </form>
            </ConfirmationModalAlert>
            <ConfirmationModalAlert
                color={"warning"}
                toggle={() => {
                    this.revokeVersionModalToggle()
                }}
                icon={"mdi-delete-variant"}
                isOpen={this.state.revokeVersionModal}
                title={"Versiyonu Yayından Kaldır"}
                description={"Yayından kaldırdığınız versiyon tüm kullanıcılar tarafından erişilemez olacaktır."}
                buttonAction={() => {
                    this.revokeVersionFiles()
                }}
            />

            <ConfirmationModalAlert
                color={"error"}
                icon={"mdi-trash-can-outline"}
                isOpen={this.state.deleteVersionModal}
                toggle={this.deleteVersionModalToggle}
                title={"Emin misiniz?"}
                description={"Versiyon kalıcı olarak silinecektir. Bu işlem geri alınamaz."}
                buttonAction={() => {
                    this.deleteVersionModalToggle()
                    this.deleteVersion()
                }}
            />

            <ConfirmationModalAlert
                toggle={() => {
                    this.setState({updatingVersionId: ''})
                    this.updateConfirmModalToggle()
                }}
                icon={"mdi-swap-vertical"}
                isOpen={this.state.updateConfirmModal}
                title={"Emin misiniz?"}
                description={"Güncel versiyon dosyaları ürün/versiyon konfigürasyonu ile okunacak ve güncel dosyalar versiyona yansıtılacaktır."}
                buttonAction={() => {
                    this.updateConfirmModalToggle()
                    this.updateVersionFiles()
                }}
            />


            <SuccessModalAlert
                toggle={this.updatedVersionModalToggle}
                isOpen={this.state.updatedVersionModal}
                title={"Güncelleme Tamamlandı!"}
                description={"Versiyon dosyaları güncellendi."}
            />
            <SuccessModalAlert
                toggle={this.downloadVersionModalToggle}
                isOpen={this.state.downloadVersionModal}
                icon={this.state.downloadProgress < 100 ? "mdi-loading mdi-spin" : ""}
                title={this.state.downloadProgress < 100 ? "Dosya Hazırlanıyor" : "Hazırlandı!"}
                description={"İndirme işleminiz başlatılacaktır."}
                hideButtons={this.state.downloadProgress < 100}
            >
                <div className={"text-center"}>{`% ${this.state.downloadProgress}`}</div>
                <Progress animated={true} barAriaValueText={`% ${this.state.downloadProgress}`} striped={true} value={this.state.downloadProgress} />
            </SuccessModalAlert>

            <SuccessModalAlert
                isOpen={this.state.updatingVersionModal}
                icon={"mdi-autorenew mdi-spin"}
                title={"Versiyon Dosyaları Güncelleniyor"}
                description={"Dosyalar ürün/versiyon konfigürasyonu kullanılarak güncelleniyor. Lütfen bekleyiniz."}
                buttonAction={() => {
                }}
                toggle={() => {
                }}
            />

            <Modal isOpen={addVersionModal} toggle={this.versionModalToggle} size={"lg"}>
                <ModalHeader>{editingVersion ? 'Versiyonu Düzenle' : 'Yeni Versiyon Ekle'}</ModalHeader>
                <ModalBody>
                    <form
                        onSubmit={this.createVersion}
                        id={"new-version-form"} name={"new-version-form"} method={"post"}>
                        {formControl.render()}
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form={"new-version-form"} color={"primary"}>Gönder</Button>
                </ModalFooter>
            </Modal>

            <Row className={"pb-2"}>
                <Col><h4>Ürün Versiyonları</h4></Col>
                <Col className={"text-right"}><Button
                    onClick={() => {
                        this.setState({editingVersion: null, versionData: default_version_data});
                        this.versionModalToggle()
                    }}
                    color={"dark"} size={"sm"}>Yeni Ekle</Button></Col>
            </Row>

            <DataTable
                ref={this.versionTableRef}
                columns={this.versionTableColumns}
                dataQuery={
                    {
                        url: `/api/Products/getVersions/${productId}`,
                        filters: {}
                    }
                }
                filters={{}}
            />
        </div>
    }

    createVersion(ev) {
        ev.preventDefault()

        let data = Object.assign({}, this.state.versionData)
        data.productId = parseInt(this.props.productId)
        let delete_items = ['product',
            'publishDate',
            'releaseOwner',
            'id',
            'sourceFile',
            'sourceFileId',
            'status',
            'encryptedFile',
            'encryptedFileId',
            'publishVersionNumber',
            'releaseType',
            'releaseDate',
            'createdDate',
            'updateDate',
            'releaseOwnerId']

        delete_items.forEach(key => {
            if (typeof data[key] !== "undefined")
                delete data[key]
        })

        if (JSON.stringify(data.ioncubeConfig) === JSON.stringify(ionCubeConfigDefault) ||
            JSON.stringify(data.ioncubeConfig) === JSON.stringify(ionCubeConfigDefaultAlt)
        )
            data.ioncubeConfig = null

        const success = () => {
            this.setState({
                addVersionModal: false,
                versionData: default_version_data,
                editingVersion: null
            })
            this.versionTableRef.current.loadData()
        }

        if (this.state.editingVersion === null) {
            VersionModel.add(data).then(r => r.json()).then(r => {
                success()
            });
        } else {
            VersionModel.update(this.state.editingVersion, data).then(r => r.json()).then(r => {
                success()
            });
        }
    }

    deleteVersion = () => {
        let self = this,
            id = this.state.editingVersion.id
        VersionModel.delete(id).then(r => {
            self.versionTableRef.current.loadData()
            toast.success("Versiyon silindi!", {
                position: "top-center"
            })
        })
    }

    downloadVersionFiles = async (vId) => {
        let self = this
        let downloader = VersionModel.downloadVersionFiles(vId)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.status+' '+response.statusText)
                }

                if (!response.body) {
                    throw Error('ReadableStream not yet supported in this browser.')
                }

                const contentEncoding = response.headers.get('content-encoding');
                const contentLength = response.headers.get(contentEncoding ? 'x-file-size' : 'content-length');
                if (contentLength === null) {
                    throw Error('Response size header unavailable');
                }

                const total = parseInt(contentLength, 10);
                let loaded = 0;

                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();

                            read();
                            function read() {
                                reader.read().then(({done, value}) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    loaded += value.byteLength;
                                    let percent = Math.ceil((loaded/total)*100)
                                    self.setState({downloadProgress:percent})
                                    controller.enqueue(value);
                                    read();
                                }).catch(error => {
                                    console.error(error);
                                    controller.error(error)
                                })
                            }
                        }
                    })
                );
            })
            .then(r => r.blob())
            .then(r => {
                let _url = window.URL.createObjectURL(r),
                    tempLink = document.createElement('a');
                tempLink.href = _url;
                tempLink.setAttribute('download', `version-${vId}-files.zip`);
                tempLink.click();
            })
    }

}

export default ProductVersionTab
