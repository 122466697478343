import {backendBaseUrl} from "../Config";
import moment from "moment";
import {Redirect} from "react-router-dom";

class Authorization {
    isLoggedIn = () => {
        let token = localStorage.getItem("auth_accessToken")
        return (typeof token === "string") && (token !== "")
    }

    doLogout = () => {
        return new Promise(function (resolve, reject) {
            let auth_keys = ["auth_id", "auth_accessToken", "auth_refreshToken", "auth_expiration"]
            auth_keys.forEach(item => {
                localStorage.removeItem(item)
            })
            resolve()
        })

    }

    doLogin = (email, pw) => {
        let _fd = new FormData()
        _fd.append("Email", email)
        _fd.append("Password", pw)
        return new Promise(function (resolve, reject) {
            fetch(backendBaseUrl + '/api/Authorization/Login', {
                method: 'POST',
                body: _fd
            }).then(r => r.json()).then(result => {
                if (typeof result.id !== "undefined") {
                    Object.keys(result).forEach((item, index) => {
                        let item_value = result[item];
                        localStorage.setItem("auth_" + item, item_value);
                    });
                    resolve();
                } else {
                    reject(result.message)
                }
            });
        })
    }

    isExpired() {
        let auth_expiration = localStorage.getItem("auth_expiration")
        let now = moment()
        return moment(auth_expiration).isBefore(now)
    }

    refresh() {
        let self = this
        return new Promise(function (resolve, reject) {
            let refresh = localStorage.getItem("auth_refreshToken")
            if (typeof refresh !== "string" || refresh === "")
                reject("NoRefresh");

            let _fd = new FormData();
            _fd.append("refreshToken", refresh)

            fetch(backendBaseUrl + '/api/Authorization/RefreshTokenLogin', {
                method: 'POST',
                body: _fd
            }).then(response => {
                if (response.ok) {
                    if (response.status === 200) {
                        return response.json()
                    } else if (response.status === 204) {
                        self.doLogout().then(function () {
                            window.location.href = '/';
                        })
                    }
                }
            }).then(result => {
                if (typeof result.id !== "undefined") {
                    Object.keys(result).forEach((item, index) => {
                        let item_value = result[item];
                        localStorage.setItem("auth_" + item, item_value);
                    });
                    resolve(result['accessToken']);
                }
            }).catch(result => {
                self.doLogout().then(function (){
                    window.location.href='/';
                })
            })
        })
    }
    async getAccessToken() {
        let self = this
        if (this.isExpired()) {
            let token = ""
            await self.refresh().then(function (tokenRefresh) {
                token = tokenRefresh;
            }).catch(function (reason) {
                if (reason === "NoRefresh") {
                    self.doLogout().then(function () {
                        return <Redirect to={"/login"}/>
                    })
                }
            })
            return "Bearer " + token
        } else {
            return "Bearer " + localStorage.getItem("auth_accessToken")
        }
    }
}

export default Authorization