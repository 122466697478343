import React from "react"
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import SalesChart from "../../Components/Charts/SalesChart";
import moment from "moment";
import NeverSold from "../../Components/Charts/NeverSold";
import SalesCategoryDispersion from "../../Components/Charts/SalesCategoryDispersion";


class SalesStats extends React.Component {
    render() {
        return (
            <div>
                <div class="bg-dark">
                    <div class="container  m-b-30">
                        <div class="row">
                            <div class="col-12 text-white p-t-40 p-b-90">
                                <h4 class="">Satış İstatistikleri</h4>
                                <p class="opacity-75 ">Satışa yönelik sunulan raporları inceleyin.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container pull-up">
                    <Card className={"m-b-30"}>
                        <CardHeader>
                            <h5><i className={"mdi mdi-chart-bar-stacked"}></i> Aylık Satış Durumu</h5>
                        </CardHeader>
                        <CardBody>
                            <SalesChart
                                start={moment("01-01-2018", "dd-mm-YYYY")}
                                end={moment().endOf("year")}
                                type={"monthly"}
                            />
                        </CardBody>
                    </Card>
                </div>
                <Container>
                    <Row>
                        <Col sm={9}>
                            <Card className={"m-b-30"}>
                                <CardHeader>
                                    <h5><i className={"mdi mdi-chart-arc"}></i> Satış Kategori Dağılımı</h5>
                                </CardHeader>
                                <CardBody>
                                    <SalesCategoryDispersion width={600} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default SalesStats