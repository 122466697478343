import React from "react"
import {withRouter} from 'react-router';
import logo from "../assets/img/grilabs.svg";
import {Link} from "react-router-dom";

class Home extends React.Component{
    render() {
        return (
            <div>
                    <div class="bg-dark">
                        <div class="container  m-b-30">
                            <div class="row">
                                <div class="col-12 text-white p-t-40 p-b-90">

                                    <h4 class="mb-3">
                                        <img src={logo} width={90} className={"mr-3"} />
                                        Lisans Yönetim Sistemi
                                    </h4>
                                    <p class="opacity-75 ">
                                        Lisans yönetim sistemi ile, lisanslı şekilde dağıtımı yapılan PHP ürünlerinizi kolayca organize edebilirsiniz.</p>
                                    <p><Link className={"btn btn-primary"} to={"/login"}>Giriş Yap <i className={"mdi mdi-arrow-right"}></i></Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container  pull-up">
                        <div class="row">

                            <div className="col-md-6">

                                <div className="card m-b-30">
                                    <div className="card-header">
                                        <h5 className="m-b-0">
                                            Ürün Yönetimi
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <p>Lisanslı ürünleri sisteme yükleyin ve kullanım durumlarını inceleyin.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card m-b-30">
                                    <div className="card-header">
                                        <h5 className="m-b-0">
                                            Otomatik ionCube Şifreleme
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <p>Sistem üzerinden açık kaynak olarak verdiğiniz PHP dosyaları otomatik olarak ionCube Encoder ile şifrelenerek, müşterilerinize şifreli şekilde sunulacaktır.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="card m-b-30">
                                    <div className="card-header">
                                        <h5 className="m-b-0">
                                            Versiyon Kontrol Entegrasyonu
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <p>Şifrelenecek dosyalar konfigürasyonu tamamladığınızda otomatik olarak Bitbucket hesabınız üzerinden okunur. Böylelikle versiyon çalışmaları sırasında dosyalarınızın takibi ile vakit kaybetmezsiniz.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        );
    }
}
export default withRouter(Home)