import React from "react"
import Chart from "react-apexcharts"
import {StatisticsModel} from "../../Models/StatisticsModel";
import moment from "moment";
import "moment/locale/tr";
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";

const StatisticModel = new StatisticsModel();

class ProductSaleChart extends React.Component {
    state = {
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
        stat_slug: 'lastmonth',

        chartType: "bar",
        series: [],
        options: {
            chart: {
                type: "bar",
                height: 350,
                zoom: {
                    enabled: false
                }
            },
            xaxis: {
                position: "top",
                categories: []
            },
            yaxis: {
                title: {
                    text: 'Satış Adedi'
                }
            }
        },
    };

    loadSaleStatistics = async () => {
        let {start, end} = this.state
        let data = [], labels = []
        await StatisticModel.getBestSellers(start, end).then(r => r.json()).then(items => {
            items.forEach(item => {
                if (typeof item.product === "object") {
                    labels.push((item.product.name).substr(0, 15) + '..')
                    data.push(item.count)
                }
            })
        })

        let _options = Object.assign({}, this.state.options)
        _options.xaxis.categories = labels.slice(0, 10)
        this.setState({
            series: [{
                name: "Satış adedi",
                data: data.slice(0, 10)
            }], options: _options
        })
    }

    componentDidMount() {
        this.loadSaleStatistics()
    }

    render() {
        return <Card>
            <CardHeader>
                <Row>
                    <Col>
                        <h5><i className={"mdi mdi-format-list-checks"}></i> En Çok Satan 10 Ürün</h5>
                    </Col>
                    <Col className={"text-right"}>
                        <ButtonGroup>
                            {
                                [
                                    {
                                        label: "Bu ay",
                                        slug: 'thismonth',
                                        start: moment().startOf("month"),
                                        end: moment().endOf("month")
                                    },
                                    {
                                        label: "Bu yıl",
                                        slug: 'thisyear',
                                        start: moment().startOf("year"),
                                        end: moment().endOf("year")
                                    },
                                    {
                                        label: "Geçen Ay",
                                        slug: 'lastmonth',
                                        start: moment().subtract(1, 'month').startOf("month"),
                                        end: moment().subtract(1, 'month').endOf("month")
                                    },
                                    {
                                        label: "Geçen Yıl",
                                        slug: 'lastyear',
                                        start: moment().subtract(1, 'year').startOf("year"),
                                        end: moment().subtract(1, 'year').endOf("year"),
                                    }
                                ].map(item => {
                                    return <Button
                                        color={this.state.stat_slug === item.slug ? 'primary' : 'secondary'}
                                        size={"sm"}
                                        onClick={() => {
                                            let states = {}
                                            states.stat_slug = item.slug
                                            states.start = typeof (item.start) !== "undefined" ? item.start : false
                                            states.end = typeof (item.end) !== "undefined" ? item.end : false
                                            this.setState(states, () => {
                                                this.loadSaleStatistics()
                                            })
                                        }}
                                    >{item.label}</Button>
                                })
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <div id={`product-chart-1`}>
                    {typeof this.state.series === "object" && (this.state.series).length ?
                        <Chart options={this.state.options} series={(this.state.series)}
                               type={this.state.chartType}
                               height={350}/> : <div className={"text-center pb-3 pt-3"}>
                            <Spinner color={"white"}/>
                        </div>}
                </div>
            </CardBody>
        </Card>;
    }
}

export default ProductSaleChart