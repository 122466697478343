import React from "react";
import {
    Button,
    ButtonGroup, Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Modal, ModalBody, ModalFooter,
    Row, Spinner
} from "reactstrap";
import DataTable from "../../Components/DataTable";
import {debounce} from "../../Config";
import 'react-day-picker/lib/style.css';
import {CategoriesModel} from "../../Models/CategoriesModel";
import moment from "moment";

const default_category_details = {
    name: '',
    parentId: 0
}

const CategoryModel = new CategoriesModel()

class ProductCategoryList extends React.Component {
    tableRef = null
    columns = [
        {
            Header: 'ID #',
            accessor: 'id',
            Cell: row => {
                return "#" + row.value
            }
        },
        {
            Header: 'Kategori Adı',
            accessor: 'name',
            Cell: row => {
                return <span>{row.value}</span>;
            }
        },
        {
            Header: 'Oluşturulma Tarihi',
            accessor: 'created',
            Cell: row => {
                return <span>{moment(row.value).format('DD-MM-YYYY HH:mm')}</span>;
            }
        },
        {
            Header: 'Düzenleme Tarihi',
            accessor: 'updated',
            Cell: row => {
                return <span>{moment(row.value).format('DD-MM-YYYY HH:mm')}</span>;
            }
        },
        {
            Header: 'İşlemler',
            Cell: (data) => {
                let row = data.row.original
                return <ButtonGroup>
                    <Button size={"sm"}
                            onClick={() => {
                                CategoryModel.getById(row.id).then(result => result.json())
                                    .then(result => {
                                        this.setState({
                                            editCategoryDetails: result,
                                            showCategoryModal: true,
                                            editCategoryId: row.id
                                        })
                                    })
                            }}
                            color={"primary"}>Düzenle</Button>
                    <Button
                        onClick={() => {
                            CategoryModel.getById(row.id).then(result => result.json())
                                .then(result => {
                                    this.setState({
                                        editCategoryDetails: result,
                                        deleteModalShow: true,
                                        editCategoryId: row.id
                                    })
                                })
                        }}
                        size={"sm"} color={"danger"}>Sil</Button>
                </ButtonGroup>
            }
        }
    ]

    state = {
        ShowSpinner: false,
        categoryNameFilter: '',
        categoryFilter: '',
        editCategoryId: null,
        showCategoryModal: false,
        editCategoryDetails: default_category_details,
        deleteModalShow: false,
        parentIdFilter: ''
    }

    constructor(props) {
        super(props);
        this.categoryModalToggle = this.categoryModalToggle.bind(this)
        this.categoryDetailChange = this.categoryDetailChange.bind(this)
        this.deleteModalToggle = this.deleteModalToggle.bind(this)
        this.tableRef = React.createRef()
    }

    categoryDetailChange = (e) => {
        let name, value;
        if (typeof e.target === "object") {
            name = e.target.name
            value = e.target.value
        } else {
            value = e.value
            name = e.name
        }

        if (name === "parentId")
            value = parseInt(value)

        let exists = this.state.editCategoryDetails
        exists[name] = value
        this.setState({editCategoryDetails: exists})
    }

    render() {
        let {
            showCategoryModal, editCategoryId, deleteModalShow, ShowSpinner
        } = this.state

        return <div>

            <Modal isOpen={deleteModalShow} toggle={this.deleteModalToggle}>
                <ModalBody>
                    <div className={"text-center"}>
                        <h3>Silmeyi onayla?</h3>
                        <p>"<strong>{this.state.editCategoryDetails.name}</strong>" isimli kategori silineceketir. Devam
                            etmek istiyor musunuz?</p>
                        <Button color={"danger"} size={"lg"} onClick={() => {
                            this.setState({
                                deleteModalShow: false,
                                editCategoryId: null,
                                ShowSpinner: false
                            })
                        }}>Vazgeç</Button>{' '}
                        <Button size={"lg"} color={"primary"} onClick={() => {
                            this.deleteCategory()
                        }}>
                            Onayla <i className={"mdi mdi-arrow-right"}></i>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={showCategoryModal} toggle={this.categoryModalToggle}>
                <div className={"modal-header w-100"}>
                    <h5 className={"modal-title"}>{editCategoryId ? "Kategoriyi Düzenle" : "Yeni Kategori Ekle"}</h5>
                    <button className={"close"} onClick={() => this.categoryModalToggle()}>
                        <span>&times;</span>
                    </button>
                </div>

                <ModalBody>
                    <form id={"category-form"} name={"category-form"} onSubmit={(e) => {
                        e.preventDefault();
                        this.submitCategoryForm()
                    }}>
                        <FormGroup>
                            <Label>Kategori Adı</Label>
                            <Input type={"text"} onChange={this.categoryDetailChange} name={"name"}
                                   value={this.state.editCategoryDetails.name}/>
                        </FormGroup>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button form={"category-form"} type={"submit"} color={"primary"}>Kaydet</Button>
                </ModalFooter>
            </Modal>

            <div className="bg-dark">
                <div className="container  m-b-30">
                    <div className="row">
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4>Ürün Kategorileri {ShowSpinner && <Spinner/>}</h4>
                            <p>Tüm ürün kategorileri listelenmektedir.</p>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editCategoryId: null,
                                        editCategoryDetails: {},
                                        showCategoryModal: true
                                    })
                                }}
                                color={"primary"}>Yeni Ekle <i className={"mdi mdi-plus"}></i></Button>
                        </div>
                        <div className="col-6 text-white p-t-40 p-b-30">
                            <h4><i className={"mdi mdi-magnify"}></i> Filtrele</h4>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType={"prepend"}>
                                        <InputGroupText>Kategori Adı</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={"text"} value={this.state.categoryNameFilter}
                                           id={"category-name-filter"}
                                           onChange={(e) => {
                                               this.setState({categoryNameFilter: e.target.value})
                                               this.onCategoryNameFilterChanged()
                                           }}
                                    />
                                    <InputGroupAddon addonType={"append"}>
                                        {this.state.categoryNameFilter &&
                                        <Button color={"danger"} onClick={() => {
                                            this.setState({categoryNameFilter: ''})
                                            this.onCategoryNameFilterChanged()
                                        }}>
                                            <i className={"mdi mdi-close"}></i>
                                        </Button>}
                                        {!this.state.categoryNameFilter && <Button onClick={() => ''}>
                                            <i className={"mdi mdi-rhombus-split"}></i>
                                        </Button>}
                                    </InputGroupAddon>

                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"container"}>
                <DataTable
                    ref={this.tableRef}
                    columns={this.columns} dataQuery={{
                    url: "/api/Categories",
                    filters: {
                        categoryName: this.state.categoryNameFilter,
                        parentId: this.state.parentIdFilter
                    }
                }}/>
            </div>

        </div>
    }

    categoryModalToggle() {
        this.setState({showCategoryModal: !this.state.showCategoryModal})
    }

    deleteModalToggle() {
        this.setState({deleteModalShow: !this.state.deleteModalShow})
    }

    submitCategoryForm() {
        let category_details = Object.assign({}, this.state.editCategoryDetails)
        let self = this
        self.setState({ShowSpinner: true})
        if (this.state.editCategoryId === null) {
            CategoryModel.add(category_details).then(result => result.json()).then(function (response) {
                self.tableRef.current.loadData()
                self.setState({
                    editCategoryDetails: default_category_details,
                    editCategoryId: null,
                    showCategoryModal: false,
                    ShowSpinner: false
                })
            })
        } else {
            delete category_details.created
            delete category_details.id
            delete category_details.parent
            delete category_details.isParent
            delete category_details.updated

            if(category_details.parentId === null)
                category_details.parentId = 0

            CategoryModel.update(this.state.editCategoryId, category_details).then(result => {
                if (result.ok) {
                    self.tableRef.current.loadData()
                    self.setState({
                        editCategoryDetails: default_category_details,
                        editCategoryId: null,
                        showCategoryModal: false,
                        ShowSpinner: false
                    })
                }
            })
        }
    }

    deleteCategory() {
        let self = this
        self.setState({ShowSpinner: true})
        CategoryModel.delete(this.state.editCategoryId).then(function () {
            self.tableRef.current.loadData()
            self.setState({
                deleteModalShow: false,
                editCategoryId: null,
                ShowSpinner: false
            })
        })
    }

    onCategoryNameFilterChanged = debounce(() => {
        this.tableRef.current.loadData()
    }, 500);
}

export default ProductCategoryList