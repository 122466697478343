import request from "../Lib/GlRequest";
import {datetime_format} from "../Config";
import moment from "moment";
import typeOf from "airbnb-prop-types/src/helpers/typeOf";

export const statTimeFormat = "YYYY-MM-DD"
export class NotFoundLicenseModel {
    delete(id) {
        let _url = '/api/NotFoundLicenses/' + id
        return request.delete(_url)
    }
}