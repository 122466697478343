let MenuItems = [
    {
        label: "Giriş",
        to: "/dashboard",
        icon: "mdi mdi-home-outline",
    },
    {
        label: "Lisanslar",
        to: "/",
        // count: 2,
        icon: "mdi mdi-layers",
        childs: [
            {
                label: "Aktif Lisanslar",
                to: "/licenses/active",
                // count: 1937,
                // letter: "Ac"
            },
            {
                label: "Deneme Lisansları",
                to: "/licenses/trial",
                // count: 1937,
                // letter: "Tr"
            },
            {
                label: "Süresi Dolan Lisanslar",
                to: "/licenses/expired",
                // count: 234,
                // letter: "Ex"
            },
            {
                label: "Geliştirici Lisansları",
                to: "/licenses/dev",
                // count: 565,
                // letter: "Dev"
            },
            {
                label: "Ücretsiz Lisanslar",
                to: "/licenses/free",
                // count: 123,
                // letter: "Fr"
            },
            {
                label: "Tüm Lisanslar",
                to: "/licenses/all",
                // count: 123,
                // letter: "All"
            }
        ]
    },

    {
        label: "Ürünler",
        to: "/",
        // count: 2,
        icon: "mdi mdi-package-variant-closed",
        childs: [
            {
                label: "Ürün Listesi",
                to: "/products/list",
                // count: 2,
                // letter: "Pro"
            },
            {
                label: "Kategoriler",
                to: "/products/categories",
                // count: 2,
                // letter: "Cat"
            }
        ]
    },
    {
        label: "Raporlar",
        to: "/",
        icon: "mdi mdi-chart-bar",
        childs: [
            {
                label: "Satış Raporları",
                to: "/stats/sales",
                // count: 2,
                // letter: "A"
            },
            {
                label: "Ürün Raporları",
                to: "/stats/products",
                // count: 2,
                // letter: "Cat"
            }
        ]
    },
    {
        label: "Ayarlar",
        to: "/",
        // count: 2,
        icon: "mdi mdi-settings-outline",
        childs: [
            {
                label: "Kullanıcı Yönetimi",
                to: "/users/list",
                // count: 2,
                // letter: "Usr"
            }
        ]
    }
];

export const backendBaseUrl = "https://lcs.gri.net";
// export const backendBaseUrl = "https://localhost:5001";
export default MenuItems;

export function debounce (fn, time) {
    let timeoutId
    return wrapper
    function wrapper (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            timeoutId = null
            fn(...args)
        }, time)
    }
}

export const licenseTypes = [
    {value:"trial", label: "Deneme Sürümü"},
    {value:"full", label: "Tam Sürüm"},
    {value:"limited", label: "Sınırlı Süreli Lisans"},
    {value:"dev", label: "Geliştirici Lisansı"},
    {value:"free", label: "Ücretsiz Lisans"},
    {value:"other", label: "Diğer"}
]
export const encoderVersions = [
    {value:"4", label: "4"},
    {value:"5", label: "5"},
    {value:"5.3", label: "5.3"},
    {value:"5.4", label: "5.4"},
    {value:"5.5", label: "5.5"},
    {value:"5.6", label: "5.6"},
    {value:"56", label: "5.6"},
    {value:"71", label: "7.1"},
    {value:"72", label: "7.2"},
    {value:"74", label: "7.4"},
    {value:"81", label: "8.1"},
    {value:"82", label: "8.2"},
]

export const phpVersions = [
    {value:"5.6", label: "5.6"},
    {value:"7.0", label: "7.0"},
    {value:"7.1", label: "7.1"},
    {value:"7.2", label: "7.2"},
    {value:"7.3", label: "7.3"},
    {value:"7.4", label: "7.4"},
    {value:"8.0", label: "8.0"},
    {value:"8.1", label: "8.1"},
    {value:"8.2", label: "8.2"}
]

export const datetime_format = "YYYY-MM-DDTHH:mm:ss[Z]"

export const customSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        flex: '1 1 auto',
        color: '#333'
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: '0 0 0 0'
    })
}
export const ionCubeConfigDefault = {
    slug: "",
    encoderVersions: [],
    ignore: [""],
    copy: [""],
    commit_sha: ""
}

export const ionCubeConfigDefaultAlt = {
    slug: "",
    encoderVersions: [""],
    ignore: [""],
    copy: [""],
    commit_sha: ""
}
