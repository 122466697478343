import React from "react"
import {StatisticsModel} from "../../Models/StatisticsModel";
import moment from "moment";
import {Button, Card, CardBody, CardHeader, Table} from "reactstrap";
import {licenseTypes} from "../../Config";
import {Link} from "react-router-dom";

const statisticModel = new StatisticsModel();

class CheckLastRequests extends React.Component {
    state = {
        expiredStillRequesting: [],
        activeNotRequesting: [],
        limitTable: 10
    }

    componentDidMount() {
        statisticModel.checkLastReqeusts(
            moment().subtract(7,"days").startOf("day"),
            moment().endOf("day"),
            "expired"
        ).then(r => r.json()).then(result => {
            this.setState({
                expiredStillRequesting: result.expiredStillRequesting,
                activeNotRequesting: result.activeNotRequesting
            })
        })
    }

    render() {
        return <div>
            <Card>
                <CardHeader>
                    <h5>
                        <i className={"mdi mdi-bomb"}></i> Süresi Bitmiş, İstek Gönderen</h5>
                </CardHeader>
                <CardBody>
                    <Table>
                        <thead>
                        <tr>
                            <th>#ID</th>
                            <th>Domain</th>
                            <th>Lisans Türü</th>
                            <th>Lisans Geçerlilik Tarihi</th>
                            <th>Son İstek Tarihi</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(this.state.expiredStillRequesting).slice(0, this.state.limitTable).map(item => {
                            return <tr>
                                <th>{item.id}</th>
                                <td>{item.domain} <Link to={`/products/${item.productId}`}>(Ürün: {typeof item.product === "object" && item.product !== null ? item.product.name : item.productId})</Link></td>
                                <td>{licenseTypes.find(x => (x.value === (item.licenseType || "other"))).label}</td>
                                <td>{moment(item.expiryDate).format('DD-MM-YYYY HH:mm')}</td>
                                <td>{moment(item.lastRequest).format('DD-MM-YYYY HH:mm')}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                    {this.state.limitTable === 10 ? <p className={"text-center"}>
                        <Button
                            onClick={() => {
                                this.setState({limitTable: 9999})
                            }}
                            size={"sm"}>Tümünü Göster</Button>
                    </p> : ""}
                </CardBody>
            </Card>
        </div>;
    }
}

export default CheckLastRequests