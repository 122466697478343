import React from "react"
import Chart from "react-apexcharts"
import {StatisticsModel} from "../../Models/StatisticsModel";
import moment from "moment";
import "moment/locale/tr";
import {Button, ButtonGroup} from "reactstrap";

const StatisticModel = new StatisticsModel();

class SalesChart extends React.Component {
    state = {
        chartType: "bar",
        series: [],
        options: {
            chart: {
                type: "bar",
                height: 350,
                zoom: {
                    enabled: false
                }
            },

            xaxis: {
                position: "top",
                categories: []
            },
            yaxis: {
                title: {
                    text: 'Satış Adedi'
                }
            }
        },
    };

    loadStatCallback = (result) => {
        let data = [], array_keys = [], dataNew = []
        {
            Object.keys(result).forEach(key => {
                let data_item = result[key]
                if (typeof data_item.count === "number" && data_item.count > 0) {
                    let startDateToMoment = moment(data_item.startDate, 'DD/MM/YYYY')
                    let detectMonth = startDateToMoment.format('MM')
                    let detectYear = startDateToMoment.format('YYYY')

                    if (array_keys.indexOf(detectYear) === -1)
                        array_keys.push(detectYear)

                    data[parseInt(detectMonth) - 1] = data_item.count
                    if (typeof data[detectYear] === "undefined")
                        data[detectYear + ''] = {name: detectYear, data: []}
                    data[detectYear + '']['data'][detectMonth - 1] = (data_item.count);
                }
            })
            array_keys.forEach(item => {
                dataNew.push(data[item])
            })
        }
        this.setState({
            series: dataNew
        })
    }

    loadStatCallbackDaily = (result) => {
        let data = [], array_keys = [], dataNew = []
        {
            Object.keys(result).forEach(key => {
                let data_item = result[key]
                if (typeof data_item.count === "number" && data_item.count > 0) {
                    let startDateToMoment = moment(data_item.date, 'DD/MM/YYYY')
                    data[startDateToMoment.format('DD/MM/YYYY')] = data_item.count;
                }
            })
        }

        let {start, end} = this.props
        for (let a = start.clone(); a.isBefore(end); a.add(1, "day")) {
            dataNew[a.format('DD') - 1] = typeof data[a.format('DD/MM/YYYY')] === "number" ? data[a.format('DD/MM/YYYY')] : 0;
        }
        this.setState({
            series: [{
                name: 'Yeni Lisans Adedi',
                data: dataNew
            }]
        })
    }

    loadSaleStatistics = async () => {
        let {type, start, end} = this.props
        let labels = []
        if (type === "monthly") {
            labels = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', "Temmuz", 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
            StatisticModel.getMonthly(start, end).then(r => r.json()).then(this.loadStatCallback)
        } else {
            for (let day = start.clone(); day.isBefore(end); day.add(1, "day")) {
                labels.push(day.format("DD"))
            }
            StatisticModel.getDaily(start, end).then(r => r.json()).then(this.loadStatCallbackDaily)
        }

        let _options = Object.assign({}, this.state.options)
        _options.xaxis.categories = labels

        if(type === "monthly") {
            _options.yaxis.title.text = `${start.format("YYYY")} - ${end.format("YYYY")} aralığı Satış Adedi`
        } else {
            _options.yaxis.title.text = start.format("MMMM/YYYY") + ' ayı Satış Adedi'
        }

        this.setState({options: _options})

    }

    componentDidMount() {
        this.loadSaleStatistics()
    }

    render() {
        return <div>
            <div id={`chart-1`}>
                {typeof this.state.series === "object" && (this.state.series).length ? <Chart options={this.state.options} series={this.state.series} type={this.state.chartType} height={350}/> : ""}
            </div>
        </div>
            ;
    }
}

export default SalesChart