import React from 'react';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import request from "./../Lib/GlRequest"

class GlSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.defaultValue,
            defaultOptions: this.props.defaultOptions,
            placeholder: this.props.placeholder,
            inputId: this.props.inputId
        };
        this.getOptions = _.debounce(this.getOptions.bind(this), 500);
        this.loadDefaultOptions();
    }

    handleChange = selectedOption => {
        if(selectedOption === null)
            selectedOption = []
        this.setState({
            selectedOption: selectedOption
        });
        if (this.props.onChange) {
            if(typeof selectedOption === "object")
                this.props.onChange(selectedOption);
            else
                this.props.onChange(selectedOption.value);
        }
    };

    mapOptionsToValues = options => {
        if (typeof options[0].username === "string") {
            return options.map(option => ({
                value: option.id,
                label: option.username
            }));
        } else {
            return options.map(option => ({
                value: option.id,
                label: option.name
            }));
        }
    };

    getOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }

        const {url} = this.props;
        let self = this
        const limit = this.props.limit || 10;
        const queryAdder = url.indexOf('?') === -1 ? '?' : '&';
        let queryParams = '';

        if (this.props.searchKey)
            queryParams += `${this.props.searchKey}=${inputValue}`;

        queryParams += `&PageSize=${limit}`;
        const fetchURL = `${url}${queryAdder}${queryParams}`;

        request.get(fetchURL).then(r => r.json()).then(response => {
            const results = response.data;
            if (self.props.mapOptionsToValues)
                callback(self.props.mapOptionsToValues(results));
            else callback(self.mapOptionsToValues(results));
        })
    };

    loadDefaultOptions() {
        const {url} = this.props;
        let self = this;
        request.get(url).then(r => r.json()).then(response => {
            self.setState({
                defaultOptions: self.mapOptionsToValues(response.data)
            });
        })
    }

    render() {
        return (
            <AsyncSelect
                {...this.props}
                inputId={this.state.inputId}
                value={this.state.selectedOption || []}
                defaultOptions={this.state.defaultOptions}
                loadOptions={this.getOptions}
                placeholder={this.state.placeholder}
                onChange={this.handleChange}
                onFocus={e => {
                    e.target.autocomplete = "nope";
                    e.target.spellcheck = "nope";
                }}
            />
        );
    }
}

export default GlSelect