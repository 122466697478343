import React from "react"
import {Button, Modal} from "reactstrap";

class ConfirmationModalAlert extends React.Component {
    render() {
        return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} centered={true}>
            <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
                <div className="px-3 pt-3 text-center">
                    <div className={"event-type " + (this.props.color ? this.props.color : "warning")}>
                        <div className="event-indicator ">
                            <i className={"mdi text-white " + (this.props.icon ? this.props.icon : "mdi-exclamation ")}
                               style={{
                                   fontSize: "60px"
                               }}></i>
                        </div>
                    </div>
                    <h3 className="pt-3">{this.props.title}</h3>
                    <p className="text-muted">
                        {this.props.description}
                    </p>
                    {this.props.children}
                </div>
            </div>
            <div className="modal-footer">
                <Button onClick={() => {
                    if (typeof this.props.buttonAction === "function")
                        this.props.buttonAction();
                    else
                        this.props.toggle()
                }} color={"primary"}>{this.props.buttonText || 'Devam Et'}</Button>
                <Button onClick={() => {
                    if (typeof this.props.buttonActionCancel === "function")
                        this.props.buttonActionCancel();
                    else
                        this.props.toggle()
                }} color={"secondary"}>{this.props.buttonText || 'Vazgeç'}</Button>
            </div>
        </Modal>
    }
}

export default ConfirmationModalAlert