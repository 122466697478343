import {Col, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import {encoderVersions, ionCubeConfigDefault} from "../Config";
import React from "react"


class IonCubeConfig extends React.Component {
    constructor(props) {
        super(props);
        if (props.value !== null) {
            let DataVar = {}
            Object.keys(props.value).forEach(key => {
                DataVar[key.charAt(0).toLowerCase() + key.slice(1)] = props.value[key]
            })
            this.state = {
                slug: DataVar.slug || ionCubeConfigDefault.slug,
                encoderVersions: DataVar.encoderVersions || ionCubeConfigDefault.encoderVersions,
                ignore: DataVar.ignore || ionCubeConfigDefault.ignore,
                copy: DataVar.copy || ionCubeConfigDefault.copy,
                commit_sha: DataVar.commit_sha || ionCubeConfigDefault.commit_sha
            }
        } else {
            this.state = ionCubeConfigDefault
        }
    }

    getConfig = () => {
        if (JSON.stringify(this.state) === JSON.stringify(ionCubeConfigDefault))
            return null

        return {
            "slug": this.state.slug,
            "encoderVersions": this.state.encoderVersions,
            "ignore": this.state.ignore,
            "copy": this.state.copy,
            "commit_sha": this.state.commit_sha
        }
    }

    change = () => {
        if (typeof this.props.onChange === "function") {
            this.props.onChange({
                name: this.props.name,
                value: this.getConfig()
            })
        }
    }

    render() {
        return <div className={"p-2 bg-light border rounded"}>
            <Row>
                <Col>
                    <Label>VCS Slug</Label>
                    <Input type={"text"}
                           value={this.state.slug}
                           onChange={e => {
                               this.setState({slug: e.target.value}, () => this.change())
                           }}/>
                </Col>
                <Col>
                    <Label>Spesifik Commit (sha)</Label>
                    <Input type={"text"}
                           value={this.state.commit_sha}
                           onChange={e => {
                               this.setState({commit_sha: e.target.value}, () => this.change())
                           }}/>
                </Col>
            </Row>
            <Row className={"pt-2"}>
                <Col>
                    <Label>Encoder Versiyonları</Label>
                    <Select
                        placeholder={"IonCube Sürümü"}
                        options={encoderVersions}
                        isMulti
                        value={encoderVersions.filter(i => {
                            return (typeof this.state.encoderVersions === "object" && this.state.encoderVersions !== null)
                                && (this.state.encoderVersions).indexOf(i.value) > -1
                        })}
                        onChange={e => {
                            this.setState({encoderVersions: e === null ? [] : e.map(e => e.value)}, () => this.change())
                        }}
                    />
                </Col>
            </Row>
            <Row className={"pt-2"}>
                <Col>
                    <Label>Hariç Tutulacak Dizinler</Label>
                    <Input
                        value={this.state.ignore.join(",")}
                        type={"text"} onChange={(e) => {
                        this.setState({ignore: (e.target.value).split(",")}, () => {
                            this.change()
                        })
                    }}/>
                </Col>
                <Col>
                    <Label>Şifrelenmeyecek Dizinler</Label>
                    <Input
                        value={this.state.copy.join(",")}
                        type={"text"} onChange={(e) => {
                        this.setState({copy: (e.target.value).split(",")}, () => {
                            this.change()
                        })
                    }}/>
                </Col>
            </Row>
        </div>;
    }

}

export default IonCubeConfig
