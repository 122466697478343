import React from "react"
import {Button, Modal} from "reactstrap";

class SuccessModalAlert extends React.Component{
    render() {
        return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} centered={true}>
            <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
                <div className="px-3 pt-3 text-center">
                    <div className="event-type success">
                        <div className="event-indicator ">
                            <i className={"mdi text-white " + (this.props.icon?this.props.icon:'mdi-check')} style={{
                                fontSize: "60px"
                            }}></i>
                        </div>
                    </div>
                    <h3 className="pt-3">{this.props.title}</h3>
                    <p className="text-muted">
                        {this.props.description}
                    </p>
                    {this.props.children}
                </div>
            </div>
            <div className="modal-footer ">
                {!this.props.hideButtons ? <Button onClick={() => {
                    if(typeof this.props.buttonAction === "function")
                        this.props.buttonAction();
                    else
                        this.props.toggle()
                }} color={"primary"}>{this.props.buttonText || 'Tamam'}</Button> : ""}
            </div>
        </Modal>
    }
}
export default SuccessModalAlert