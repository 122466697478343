import React from "react"
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap";
import classnames from "classnames";
import Authorization from "../Lib/Authorization";
import {Link} from "react-router-dom";

const auth = new Authorization();

class Header extends React.Component {

    state = {
        userDropdown: false,
        showNotifications: false
    }

    userDropdownToggle = () => {
        this.setState({
            userDropdown: !this.state.userDropdown
        })
    }
    showNotificationsToggle = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        })
    }

    render() {
        let {userDropdown, showNotifications} = this.state
        let {history} = this.props

        return <header className="admin-header">
            <a
                onClick={() => {
                    document.body.classList = "sidebar-open"
                }}
                href="#" className="sidebar-toggle" data-toggleclass="sidebar-open" data-target="body">

            </a>
            <Nav className={"mr-auto my-auto"}>
                <NavItem className={"align-items-center"}>
                    <NavLink>
                        <i className=" mdi mdi-magnify mdi-24px align-middle"></i>
                    </NavLink>
                </NavItem>
            </Nav>
            <Nav className={"ml-auto align-items-center"}>
                <NavItem>
                    <Dropdown isOpen={showNotifications} toggle={this.showNotificationsToggle}>
                        <DropdownToggle tag={"a"} onClick={() => this.showNotificationsToggle()}>
                            <i className="mdi mdi-24px mdi-bell-outline"></i>
                        </DropdownToggle>
                        <DropdownMenu className={"notification-container"} right={true}>
                            <div className="d-flex p-all-15 bg-white justify-content-between border-bottom ">
                                <Link><i className="mdi mdi-18px mdi-settings text-muted"></i></Link>
                                <span className="h5 m-0">Bildirimler</span>
                                <Link><i className={"mdi mdi-18px mdi-notification-clear-all text-muted"}></i></Link>
                            </div>
                            <div className="notification-events bg-gray-300">
                                <div className="text-overline m-b-5">today</div>
                                <Link className="d-block m-b-10">
                                    <div className="card">
                                        <div className="card-body"><i
                                            className="mdi mdi-circle text-success"></i> All systems operational.
                                        </div>
                                    </div>
                                </Link>
                                <Link className="d-block m-b-10">
                                    <div className="card">
                                        <div className="card-body"><i className="mdi mdi-upload-multiple "></i> File
                                            upload successful.
                                        </div>
                                    </div>
                                </Link>

                                <Link className="d-block m-b-10">
                                    <div className="card">
                                        <div className="card-body">
                                            <i className="mdi mdi-cancel text-danger"></i> Your holiday has been
                                            denied
                                        </div>
                                    </div>
                                </Link>


                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
                <NavItem
                    className={"cursor-pointer dropdown"}
                    isOpen={userDropdown}
                    toggle={this.userDropdownToggle}
                    onClick={() => this.userDropdownToggle()}
                >
                    <NavLink>
                        <div className="avatar avatar-sm avatar-online">
                            <span className="avatar-title rounded-circle bg-dark">V</span>
                        </div>
                    </NavLink>
                    <UncontrolledDropdown>
                        <DropdownMenu
                            toggle={this.userDropdownToggle}
                            className={classnames("mr-2", {show: userDropdown})} right={true}>
                            <DropdownItem>Şifre Değiştir</DropdownItem>
                            <div className="dropdown-divider"></div>
                            <DropdownItem onClick={() => {
                                auth.doLogout().then(function () {
                                    history.push('/login');
                                })
                            }}>Çıkış Yap</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </NavItem>
            </Nav>
        </header>
    }
}

export default Header